import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';
import { useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

import { ORDERS } from 'app/src/components/Orders/queries';
import ClientContext from 'app/src/contexts/ClientContext';
import { Button, ButtonGroup } from 'app/src/elements/buttons';
import { Loading } from 'app/src/elements/DataState';
import mobileTable from 'app/src/elements/mobileTable';
import RelayTable from 'app/src/elements/RelayTable';
import { downloadReport } from 'app/src/elements/ReportDownload';
import { Picker } from 'app/src/elements/Picker';
import {
  Title,
  TitleWithLinks,
  Text,
  Setting,
  SettingLabel,
} from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { rest } from 'app/src/utils/rest';
import { Link, useParams, useSearchParams } from 'app/src/utils/routing';

import MobileRow from './MobileRow';
import Tag from './show';
import { TAGS } from './queries';

export const columns = [
  {
    key: 'serialNumber',
    label: 'Tag',
    sort: 'asc',
  },
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value.label,
  },
  {
    key: 'purchaser',
    label: 'Purchaser',
  },
  {
    key: 'soldOn',
    label: 'Issued',
    content: ({ value }) => (
      value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT) : ''
    ),
  },
  {
    key: 'vin',
    label: 'VIN',
  },
  {
    key: 'year',
    label: 'Year',
  },
  {
    key: 'makeLabel',
    label: 'Make',
    sort: 'prevent',
  },
  {
    key: 'model',
    label: 'Model',
  },
  {
    key: 'type',
    label: 'Tag Size',
    value: ({ value }) => 'M' === value ? 'Motorcycle' : 'Automobile',
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
  },
];

const nodes = collection => (
  collection ? collection.edges.map(edge => edge.node) : []
);

export const setupActions = ({ columns, setShowDetail, setShowVoid }) => {
  const actionColumn = { ...columns[columns.length - 1] };

  actionColumn.content = ({ entry }) => {
    const registerPath =
      `/tags/register/${entry.serialNumber}/${entry.type}/${entry.dealer.id}`;

    if (!entry.registeredAt) {
      return (
        <Actions>
          {entry.voidAt ? (
            <Action first>
              <Pressable onPress={() => setShowDetail(entry)}>
                <ActionText>VOIDED - Click to change</ActionText>
              </Pressable>
            </Action>
          ) : (
            <>
              <Action first>
                <Link to={registerPath}>
                  <ActionText>Log Tag</ActionText>
                </Link>
              </Action>

              {entry.canVoid && (
                <Action>
                  <Pressable onPress={() => setShowVoid(entry)}>
                    <ActionText>Void Tag</ActionText>
                  </Pressable>
                </Action>
              )}
            </>
          )}
        </Actions>
      );
    } else {
      return (
        <Actions>
          <Action first>
            <Pressable onPress={() => setShowDetail(entry)}>
              <ActionText>View Details</ActionText>
            </Pressable>
          </Action>

          {entry.canEdit && (
            <Action>
              <Link to={`/tags/${entry.id}/edit`}>
                <ActionText>Edit Details</ActionText>
              </Link>
            </Action>
          )}

          {entry.canVoid && (
            <Action>
              <Pressable onPress={() => setShowVoid(entry)}>
                <ActionText>Void Sale</ActionText>
              </Pressable>
            </Action>
          )}

          {entry.voidAt && (
            <>
              <Action>
                <Pressable onPress={() => setShowDetail(entry)}>
                  <ActionText>VOIDED</ActionText>
                </Pressable>
              </Action>

              {entry.canReregister && (
                <Action>
                  <Link to={registerPath}>
                    <ActionText>Log Tag</ActionText>
                  </Link>
                </Action>
              )}
            </>
          )}
        </Actions>
      );
    }
  };

  return [...columns.slice(0, -1), actionColumn];
};

const Tags = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsOrderId = useParams().orderId;
  const {
    credentials,
    currentUser,
    isMobile,
    isTablet,
  } = useContext(ClientContext);
  const [downloading, setDownloading] = useState();
  const [refetch, setRefetch] = useState();
  const [showDetail, setShowDetail] = useState();
  const [showVoid, setShowVoid] = useState();
  const [dealerId, setDealerId] = useState(searchParams.get('dealer-id'));
  const [orderId, setOrderId] = useState(paramsOrderId);
  const [scopes, setScopes] = useState([]);
  const [data, setData] = useState();

  const queryVariables = {
    dealerId: (dealerId || null),
    orderId: (orderId || null),
    scopes,
  };

  const handleDownload = downloadProps => {
    downloadReport({
      ...downloadProps,
      path: 'tag-log',
      params: { scopes, 'dealer-id': dealerId, 'order-id': orderId }
    })
  };

  const handlePrintLog = () => {
    setDownloading('log');

    rest({
      path: `pdf/summary/${orderId}.pdf`,
      options: { headers: { Accept: 'application/pdf' } },
      credentials,
    }).then(response => {
      response.blob().then(blob => {
        const dataUrl = window.URL.createObjectURL(blob);
        window.open(dataUrl);
        setDownloading(false);
      });
    });
  };

  const orders = nodes(data?.orders);

  const printLogLabel = orderId ?
    'Print Log' :
    'Select Order to Print';

  const mobileGroupStyle = {
    flexDirection: 'column', marginTop: 10, marginBottom: 10
  };
  const mobileButtonStyle = { width: '100%', marginTop: 0, marginBottom: 0 };
  const tabletButtonStyle = { marginTop: 0 }
  const responsiveButtonStyle = isMobile ? mobileButtonStyle: tabletButtonStyle;

  const buttons = (
    <ButtonGroup style={isMobile ? mobileGroupStyle : {}}>
      <Button
        label={
          'xlsx' === downloading ? '...' : 'Download Detailed Spreadsheet'
        }
        style={
          isTablet ? responsiveButtonStyle : { width: 400, marginTop: -20 }
        }
        onPress={() => (
          handleDownload({ credentials, type: 'xlsx', setDownloading })
        )}
      />

      <Button
        label={'pdf' === downloading ? '...' : printLogLabel}
        style={isTablet ? responsiveButtonStyle : { marginTop: -20 }}
        onPress={handlePrintLog}
        disabled={!orderId}
      />
    </ButtonGroup>
  );

  const columnsWithActions = setupActions({
    columns, setShowDetail, setShowVoid
  });

  const dealer = data?.dealer || data?.order?.dealer;

  if (dealer) {
    columnsWithActions[1] = {
      ...columnsWithActions[1],
      hidden: true,
    };
  }

  if (isTablet) {
    columnsWithActions[1] = {
      ...columnsWithActions[1],
      hidden: true,
    };

    columnsWithActions[4] = {
      ...columnsWithActions[4],
      value: ({ value }) => (
        value ? value.substring(value.length - 6, value.length) : ''
      ),
    };

    columnsWithActions[7] = {
      ...columnsWithActions[7],
      hidden: true,
    };

    columnsWithActions[8] = {
      ...columnsWithActions[8],
      hidden: true,
    };
  }

  const titleDivider = isMobile ? '' : ' - ';
  const titleStyle = isMobile ?
    { flexDirection: 'column' } :
    { justifyContent: 'flex-start' };
  const titleDetailStyle = isMobile ? { fontSize: 16 } : {};

  const orderPath = `/orders/${data?.order?.id}` +
    (currentUser.isEmployee ? '' : '/tags');

  return (
    <View>
      <TitleWithLinks style={titleStyle}>
        <Title withLinks>Tag Log</Title>
        {dealer && (
          <Link to={`/dealers/${dealer.id}`}>
            <Title withLinks style={titleDetailStyle}>
              {titleDivider}{dealer.label}
            </Title>
          </Link>
        )}
        {data?.order && (
          <Link to={orderPath}>
            <Title withLinks style={titleDetailStyle}>
              {titleDivider}{data.order.label}
            </Title>
          </Link>
        )}
      </TitleWithLinks>

      {currentUser.userDealers.length > 1 && !paramsOrderId && (
        <Setting style={isMobile ? { flexDirection: 'column', gap: 0 } : {}}>
          <SettingLabel>Limit to Dealer</SettingLabel>
          <Picker
            selectedValue={dealerId || ""}
            onValueChange={value => {
              searchParams.set('dealer-id', value);
              setSearchParams(searchParams);
              setDealerId(value);
            }}
          >
            <Picker.Item value="" label="All Dealers" />
            {currentUser.userDealers.map(userDealer => (
              <Picker.Item
                key={userDealer.id}
                value={userDealer.dealer.id}
                label={userDealer.dealer.label}
              />
            ))}
          </Picker>
        </Setting>
      )}

      {!paramsOrderId && (
        <Setting style={isMobile ? { flexDirection: 'column', gap: 0 } : {}}>
          <SettingLabel>Limit to Order</SettingLabel>
          <Picker
            selectedValue={orderId || ""}
            onValueChange={value => setOrderId(value)}
          >
            <Picker.Item value="" label="All Orders" />
            {orders.map(order => (
              <Picker.Item
                key={order.id}
                value={order.id}
                label={order.label}
              />
            ))}
          </Picker>
        </Setting>
      )}

      <Setting>
        <SettingLabel>Only Show Tags With Data</SettingLabel>
        <Switch
          value={scopes.includes('registered')}
          onValueChange={value => setScopes(value ? ['registered'] : [])}
          activeTrackColor={colors.lightButton}
          activeThumbColor={colors.button}
        />
      </Setting>

      <RelayTable
        query={TAGS}
        queryName="tags"
        queryVariables={queryVariables}
        columns={columnsWithActions}
        buttons={buttons}
        dataUpdated={setData}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileRow }}
      />

      {showDetail && (
        <Tag tag={showDetail} setShow={setShowDetail} refetch={refetch} />
      )}

      {showVoid && (
        <Tag tag={showVoid} setShow={setShowVoid} refetch={refetch} voidTag />
      )}
    </View>
  );
};

export default Tags;
