import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useQuery } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { Picker } from 'app/src/elements/Picker';
import { StyledPicker } from 'app/src/elements/inputs';
import mobileTable from 'app/src/elements/mobileTable';
import { Title, Text } from 'app/src/styles';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { datetimeShort } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

import MobileNonAdrPreRegistrationsRow from './MobileNonAdrPreRegistrationsRow';
import { DEALERS_NON_ADR_PRE_REGISTRATION } from './queries';

const Option = styled(View)`
  flex-direction: row;
  gap: 4px;
  align-items: baseline;
`;

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'licenses',
    label: 'License #s',
    sort: 'prevent',
    value: ({ value }) => value.map(license => (
      `${license.licenseType}${license.licenseNumber}`
    )).join(', '),
  },
  {
    key: 'activeMember',
    label: 'Member?',
    value: ({ value }) => value ? 'Yes' : ''
  },
  {
    key: 'preRegistrationSubscriptionStatus',
    label: 'Subscription',
  },
  {
    key: 'nonAdrTagCount',
    label: 'Non-ADR Temp Tags Registered',
  },
  {
    key: 'registeredTagCount',
    label: 'Total Registered',
  },
  {
    key: 'lastNonAdrTagRegisteredAt',
    label: 'Last non-ADR registration',
    content: datetimeShort,
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Actions>
        <Action first>
          <Link to={`/dealers/${entry.id}`}>
            <ActionText>View</ActionText>
          </Link>
        </Action>
        <Action>
          <Link to={`/dealers/${entry.id}/edit`}>
            <ActionText>Edit</ActionText>
          </Link>
        </Action>
      </Actions>
    )
  },
];

const DAYS_OPTIONS = [...Array(365).keys()];

const NonAdrPreRegistrations = () => {
  const { isMobile } = useContext(ClientContext);
  const [days, setDays] = useState(30);

  const { data } = useQuery(DEALERS_NON_ADR_PRE_REGISTRATION, {
    variables: { days }
  });

  if (!data) { return <Loading />; }

  return (
    <View>
      <Title>
        Dealers who have pre-registered non-ADR temp tags in past {days} days.
      </Title>

      <Option>
        <Text>Change Days:</Text>
        <StyledPicker
          selectedValue={days}
          onValueChange={value => setDays(parseInt(value))}
        >
          {DAYS_OPTIONS.map(option => (
            <Picker.Item key={option} value={option} label={option} />
          ))}
        </StyledPicker>
      </Option>

      <DataTable.Simple
        pageLength={100}
        data={data.dealersNonAdrPreRegistration}
        columns={columns}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileNonAdrPreRegistrationsRow }}
      />
    </View>
  );
};

export default NonAdrPreRegistrations;
