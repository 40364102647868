import React, { useContext } from 'react';
import { View, Pressable } from 'react-native';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Link } from 'app/src/utils/routing';

const Container = styled(View)`
  ${props => props.isMobile ? `
    width: 90vw;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: black;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  ` : `
    flex-direction: row;
    column-gap: 10px;
    margin: 5px;
  `}
`;

const Name = styled(View)`
  ${props => props.isMobile ? '' : `
    width: 400px;
  `}
`;

const NameText = styled(Text)`
  font-weight: 600;
  ${props => props.inactive ? 'color: gray;' : ''}
`;

const Detail = styled(View)`
  ${props => props.isMobile ? '' : `
    width: 300px;
  `}
`;

const DealerResult = ({ result, isMobile }) => {
  const name = result.licenses
    .filter(license => !license.inactiveAt)
    .map(license => license.licenseString).join('/') +
    ' - ' + result.name;

  return (
    <Link to={`/dealers/${result.id}`}>
      <Container isMobile={isMobile}>
        <Name isMobile={isMobile}>
          <NameText inactive={result.inactiveAt}>{name}</NameText>
        </Name>
        <Detail isMobile={isMobile}>
          <Text>{result.city}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const TagResult = ({ result, isMobile }) => {
  let url = `/dealers/${result.dealer.id}/pre-registrations`;

  if (!result.registeredAt && result.order) {
    url = `/orders/${result.order.id}/tags`;
  }

  url = url + `?search-term=${result.serialNumber}&t=${new Date().getTime()}`;

  return (
    <Link to={url}>
      <Container isMobile={isMobile}>
        <Name isMobile={isMobile}>
          <NameText>{result.serialNumber} - {result.vin}</NameText>
        </Name>
        <Detail isMobile={isMobile}>
          <Text>{result.dealer.name}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const UserDealerResult = ({ result, isMobile }) => {
  return (
    <Link to={`/dealers/${result.dealer.id}`}>
      <Container isMobile={isMobile}>
        <Name isMobile={isMobile}>
          <NameText>{result.fullName} - {result.email}</NameText>
        </Name>
        <Detail isMobile={isMobile}>
          <Text>{result.dealer.name}</Text>
        </Detail>
      </Container>
    </Link>
  );
};

const Result = ({ result, setTerm }) => {
  const { isMobile } = useContext(ClientContext);

  let ResultLink = TagResult;

  if ('Dealer' === result.__typename) {
    ResultLink = DealerResult;
  } else if ('UserDealer' === result.__typename) {
    ResultLink = UserDealerResult;
  }

  return (
    <Pressable
      onPress={() => setTerm('')}
      style={state => (
        { backgroundColor: state.hovered ? 'lightgray' : 'none' }
      )}
    >
      <ResultLink result={result} isMobile={isMobile} />
    </Pressable>
  );
};

export default Result;
