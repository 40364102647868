import React, { useContext } from 'react';
import { View } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import ExternalLink from 'app/src/elements/ExternalLink';
import { Text } from 'app/src/styles';

const Video = ({ url, title }) => {
  const { isMobile } = useContext(ClientContext);

  const dimensions = isMobile ?
    { width: '372', height: '208' } :
    { width: '560', height: '312' };

  return (
    <iframe
      {...dimensions}
      src={url}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  );
};

export default Video;
