import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const EditPreRegistration = () => {
  return (
    <View>
      <Subtitle>
        Editing Pre-Registration Information
      </Subtitle>

      <View>
        <Text>
          Before pre-registration information is submitted to Service Oklahoma,
          you can make updates to the information, including correcting the
          tag number, vehicle or purchaser information, or clearing the
          pre-registration completely. After data has been submitted to
          Service Oklahoma, if you need to make changes, you will have
          to{' '}
          <Link to="/how-to/pre-registration/void">
            void the prior pre-registration and re-submit.
          </Link>
        </Text>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text>Steps to edit a Pre-Registration:</Text>

        <Step number={1}>
          Click "Pre-Registration History" in the top menu.
        </Step>

        <Step number={2}>
          Find the tag and click "Edit Sale" in the right-hand column. If there
          is no "Edit Sale" link, that indicates that the pre-registration
          has already been submitted to Service Oklahoma.
        </Step>

        <Step number={3}>

          <View style={{ flex: 1 }}>
            <Text>
              The screen will show a form with the vehicle and purchase details,
              as well as links at the top depending on what edits are needed.
            </Text>

            <Text>
              • Edit Tag number - if you need to change the tag number
              (for example, if the wrong tag number was entered, or the
              purchaser changed their preference about receiving a new tag
              or keeping their tag), click the "Edit Tag Number" link
              near the top of the page. Enter the corrected tag number and
              click "Update".
            </Text>

            <Text>
              • Clear all data - if you need to completely clear this
              pre-registration information, click the "Clear Pre-Registration
              data associated with this tag." A confirmation dialog will
              appear; click the green "Clear Tag Data" button to confirm.
              This is not available for Keep Tag Pre-Registrations. For Keep
              Tags, void the pre-registration.
            </Text>

            <Text>
              • Correct vehicle or purchaser information - Edit the appropriate
              details and click the green "Save" button at the bottom.
            </Text>
          </View>
        </Step>
      </View>
    </View>
  );
};

export default EditPreRegistration;
