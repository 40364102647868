import React, { useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import { Loading } from 'app/src/elements/DataState';
import { Title } from 'app/src/styles';
import { useNavigate } from 'app/src/utils/routing';

import { CREATE_SUPPLY } from './queries';

export const FIELDS = {
  name: {
    label: 'Name',
    rules: {
      required: true,
      maxLength: { value: 20, message: 'Maxiumum 20 characters' }
    }
  },
  packType: {
    label: 'Pack Type',
    hint: '(e.g. box or roll)',
    rules: {
      required: true,
      maxLength: { value: 20, message: 'Maxiumum 20 characters' }
    }
  },
  tagsPerPack: {
    label: 'Tags per pack',
    type: 'int',
    rules: {
      required: true,
    }
  },
};

const NewSupply = () => {
  const [formStatus, setFormStatus] = useState();
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const [createSupply] = useMutation(CREATE_SUPPLY, {
    onCompleted: () => navigate('/supplies')
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    createSupply({ variables: prepareInput(input, FIELDS) });
  };

  return (
    <View>
      <Title>Add Supply</Title>

      <Fields
        fields={FIELDS}
        control={control}
        errors={errors}
      />

      {'submitting' === formStatus ? (
        <Loading />
      ) : (
        <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
      )}
    </View>
  );
};

export default NewSupply;
