import React, { useContext, useState } from 'react';
import { View } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button, ButtonGroup } from 'app/src/elements/buttons';
import mobileTable from 'app/src/elements/mobileTable';
import RelayTable from 'app/src/elements/RelayTable';
import { downloadReport } from 'app/src/elements/ReportDownload';
import { Picker } from 'app/src/elements/Picker';
import {
  Title,
  TitleWithLinks,
  Text,
  Hint,
  Setting,
  SettingLabel,
} from 'app/src/styles';
import { Link, useParams } from 'app/src/utils/routing';

import { columns, setupActions } from './index';
import MobileRow from './MobileRow';
import Tag from './show';
import { TAGS } from './queries';

const PreRegistrations = () => {
  const paramsDealerId = useParams().dealerId;
  const {
    credentials,
    currentUser,
    isMobile,
    isTablet,
  } = useContext(ClientContext);
  const [downloading, setDownloading] = useState();
  const [refetch, setRefetch] = useState();
  const [showDetail, setShowDetail] = useState();
  const [showVoid, setShowVoid] = useState();
  const [dealerId, setDealerId] = useState(paramsDealerId);
  const [data, setData] = useState();

  const handleDownload = downloadProps => {
    downloadReport({
      ...downloadProps,
      path: 'preregistration',
      params: { 'dealer-id': dealerId }
    })
  };

  const mobileButtonStyle = { width: '100%', marginTop: 0, marginBottom: 0 };

  const buttonWidth = isTablet ? 200 : 400;

  const buttons = (
    <ButtonGroup>
      <Button
        label={
          'xlsx' === downloading ? '...' : 'Download Detailed Spreadsheet'
        }
        style={
          isMobile ? mobileButtonStyle : { width: buttonWidth, marginTop: -20 }
        }
        onPress={() => (
          handleDownload({ credentials, type: 'xlsx', setDownloading })
        )}
      />
    </ButtonGroup>
  );

  const columnsWithActions = setupActions({
    columns, setShowDetail, setShowVoid
  });

  columnsWithActions[0] = { ...columnsWithActions[0], sort: null };

  if (data?.dealer) {
    columnsWithActions[1] = {
      ...columnsWithActions[1],
      hidden: true,
    };
  }

  if (isTablet) {
    columnsWithActions[1] = {
      ...columnsWithActions[1],
      hidden: true,
    };

    columnsWithActions[4] = {
      ...columnsWithActions[4],
      value: ({ value }) => (
        value ? value.substring(value.length - 6, value.length) : ''
      ),
    };

    columnsWithActions[7] = {
      ...columnsWithActions[7],
      hidden: true,
    };

    columnsWithActions[8] = {
      ...columnsWithActions[8],
      hidden: true,
    };
  }

  const titleDivider = isMobile ? '' : ' - ';
  const titleStyle = isMobile ?
    { flexDirection: 'column' } :
    { justifyContent: 'flex-start' };
  const titleDetailStyle = isMobile ? { fontSize: 16 } : {};

  return (
    <View>
      <TitleWithLinks style={titleStyle}>
        <Title withLinks>Pre-Registration History</Title>
        {data?.dealer && (
          <Link to={`/dealers/${data.dealer.id}`}>
            <Title withLinks style={titleDetailStyle}>
              {titleDivider}{data.dealer.label}
            </Title>
          </Link>
        )}
      </TitleWithLinks>

      {!isMobile && (
        <Hint>
          This screen shows similar data as the Tag Log screen,
          but is filtered to show recently submitted pre-registration
          information.
        </Hint>
      )}

      {currentUser.userDealers.length > 1 && !paramsDealerId && (
        <Setting
          style={
            isMobile ? { flexDirection: 'column', gap: 0 } : { marginTop: 10 }
          }
        >
          <SettingLabel>Limit to Dealer</SettingLabel>
          <Picker
            selectedValue={dealerId || ''}
            onValueChange={value => setDealerId(value)}
          >
            <Picker.Item value="" label="All Dealers" />
            {currentUser.userDealers.map(userDealer => (
              <Picker.Item
                key={userDealer.id}
                value={userDealer.dealer.id}
                label={userDealer.dealer.label}
              />
            ))}
          </Picker>
        </Setting>
      )}

      <RelayTable
        query={TAGS}
        queryName="tags"
        queryVariables={{
          scopes: ['recently_registered'],
          dealerId: dealerId || null,
        }}
        columns={columnsWithActions}
        buttons={buttons}
        dataUpdated={setData}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileRow }}
      />

      {showDetail && (
        <Tag tag={showDetail} setShow={setShowDetail} refetch={refetch} />
      )}

      {showVoid && (
        <Tag tag={showVoid} setShow={setShowVoid} refetch={refetch} voidTag />
      )}
    </View>
  );
};

export default PreRegistrations;
