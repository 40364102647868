import React, { useContext } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import {
  Fields,
  defaultValues,
  prepareInput,
  EMAIL_PATTERN
} from 'app/src/elements/forms';
import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';

import { INVITE_USER } from './queries';

const FIELDS = {
  email: {
    rules: {
      required: true,
      pattern: { value: EMAIL_PATTERN, message: 'Enter a valid email' },
    },
  },
  firstName: { rules: { required: true }, },
  lastName: { rules: { required: true } },
  admin: {
    label: 'Admin User? (can manage other users)',
    type: 'boolean',
  },
};

const NewUser = ({ setShow, refetch, dealer }) => {
  const { currentUser } = useContext(ClientContext);

  const fields = { ...FIELDS };

  const dealerDefaults = {};

  let dealersCanAdmin = [];

  if (currentUser.isEmployee) {
    dealersCanAdmin = [dealer];
    dealerDefaults[dealer.id] = true;
  } else {
    const dealerNicknames = currentUser.userDealers
      .filter(userDealer => userDealer.admin && userDealer.nickname);

    if (dealerNicknames.length > 0) {
      fields.copyNicknames = {
        label: 'Copy my dealer nickname(s)?',
        type: 'boolean',
      };
    }

    dealersCanAdmin = currentUser.userDealers
      .filter(userDealer => userDealer.admin)
      .map(userDealer => userDealer.dealer);

    dealersCanAdmin.forEach(dealer => {
      dealerDefaults[dealer.id] = true;

      if (dealersCanAdmin.length > 1) {
        fields[`dealers.${dealer.id}`] = {
          label: dealer.label,
          type: 'boolean',
        };
      };
    });
  }

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: defaultValues({
      fields,
      resource: { dealers: dealerDefaults }
    })
  });

  const [inviteUser] = useMutation(INVITE_USER, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  const onSubmit = input => {
    const dealerIds = input.dealers ?
      Object.keys(input.dealers).filter(id => input.dealers[id]) :
      dealersCanAdmin.map(dealer => dealer.id);

    inviteUser({
      variables: {
        ...prepareInput(input, fields),
        dealerIds,
      }
    });
  };

  return (
    <Modal title="Add User" setShow={setShow}>
      <Fields
        fields={fields}
        control={control}
        errors={errors}
      />

      <Text>
        If there is an account already associated with this email, the user
        will receive an email notifying them they have access to this
        dealership.
      </Text>
      <Text>
        Otherwise, the user will receive an email asking them to first set
        their password.
      </Text>


      <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
    </Modal>
  );
};

export default NewUser;
