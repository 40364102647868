import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const DealershipNicknames = () => {
  return (
    <View>
      <Subtitle>Dealership Nicknames</Subtitle>

      <View style={{ gap: 10 }}>
        <View>
          <Text>
            If you have access to multiple dealerships, labels and dropdowns
            will default to including the dealershipship name, license
            number(s) and part of the street address to help you differentiate.
            However, you can set alternate nicknames that are more meaningful
            to you. To do so:
          </Text>

          <Step number={1}>
            From the top menu, click "Dealerships".
          </Step>

          <Step number={2}>
            In the Nickname column, click the green pencil icon. (If on a mobile
            device, click "Set Nickname" below the dealer information.)
          </Step>

          <Step number={3}>
            Enter the desired nickname and click the green "Set Nickname"
            button.
          </Step>

          <Step number={4}>
            If you wish to clear the nickname, repeat the above steps, but click
            "Clear Nickname" in the dialog box.
          </Step>
        </View>

        <Text>
          Nicknames are only assigned to your user account. If you have
          nicknames set and can add users, you will have the option
          to initially set their nicknames to match your own.
        </Text>
      </View>
    </View>
  );
};

export default DealershipNicknames;
