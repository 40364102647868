import React from 'react';
import { View, Pressable } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCirclePlus, faList } from '@fortawesome/free-solid-svg-icons';

import RelayTable from 'app/src/elements/RelayTable';
import { Title, Text } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { delimited } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

import { SUPPLIES } from './queries';

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'packType',
    label: 'Pack Type',
  },
  {
    key: 'tagsPerPack',
    label: 'Tags Per Pack',
    content: delimited,
  },
  {
    key: 'actions',
    label: 'Actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/supplies/${entry.id}`}>
        <ActionText>View/Edit</ActionText>
      </Link>
    )
  },
];

const Supplies = () => {
  return (
    <View>
      <Title>Supplies</Title>

      <View style={{ gap: 16 }}>
        <Link to="/supplies/new">
          <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
            <FontAwesomeIcon icon={faCirclePlus} color="green" size={24} />
            <Text>Add Supply</Text>
          </View>
        </Link>

        <Link to="/supplies/inventory">
          <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
            <FontAwesomeIcon icon={faList} color="green" size={24} />
            <Text>Perform Inventory</Text>
          </View>
        </Link>
      </View>

      <RelayTable
        query={SUPPLIES}
        queryName="supplies"
        columns={columns}
      />
    </View>
  );
};

export default Supplies;
