import React, { useState } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCirclePlus, faEye } from '@fortawesome/free-solid-svg-icons';

import NewUser from 'app/src/components/Users/new';
import User from 'app/src/components/Users/show';
import EditableDetails from 'app/src/elements/EditableDetails';
import useToggle from 'app/src/hooks/useToggle';
import { Text } from 'app/src/styles';

const UserDealer = ({ userDealer, refetch }) => {
  return (
    <View key={userDealer.id} style={{ marginBottom: 10 }}>
      <EditableDetails
        modalContent={User}
        title={userDealer.email}
        id={userDealer.userId}
        icon={faEye}
        screenRefetch={refetch}
        canEdit
      >
        <Text>User Name: {userDealer.fullName}</Text>
        <Text>Email: {userDealer.email}</Text>
        {userDealer.admin && <Text style={{ fontWeight: 600 }}>Admin</Text>}
        {userDealer.nickname && (
          <Text style={{ fontWeight: 600 }}>
            Nickname for this Dealer: {userDealer.nickname}
          </Text>
        )}
      </EditableDetails>
    </View>
  );
};

const Users = ({ dealer, refetch }) => {
  const [showNew, toggleShowNew] = useToggle();

  return (
    <>
      {dealer.userDealers.map(userDealer => (
        <UserDealer
          key={userDealer.id}
          userDealer={userDealer}
          refetch={refetch}
        />
      ))}

      <Pressable onPress={toggleShowNew}>
        <FontAwesomeIcon
          icon={faCirclePlus}
          color="green"
          size={24}
        />
      </Pressable>

      {showNew && (
        <NewUser
          setShow={toggleShowNew}
          dealer={dealer}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default Users;
