import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import DealershipNicknames from './Nicknames';

const Dealerships = () => {
  return (
    <View>
      <Subtitle>Dealerships</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          Your account can be be linked to one or more dealerships. To see
          which dealerships you have access to, click "Dealerships" in the
          top menu.
        </Text>

        <Text>
          If you need access to another dealership that is already registered,
          contact a current administrator to grant access
          <Link to="/how-to/users" spaces>
            [Click here for more information].
          </Link>
          You can also setup a new dealership. To do so, on the dealerships
          screen, click "Add Dealer" above the table and enter the information
          for the other dealership following the same steps for setting up a
          dealer on a new account
          <Link to="/how-to/account/create" spaces>
            [Click here for more information].
          </Link>
        </Text>
      </View>

      <DealershipNicknames />
    </View>
  );
};

export default Dealerships;
