import { View } from 'react-native';
import styled from 'styled-components/native';

import colors from './colors';
import { Text } from './index';

export const MobileEntryContainer = styled(View)`
  margin-bottom: 6px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.button};
`;

export const MobileEntryTitle = styled(Text)`
  font-weight: 600
`;

export const MobileEntryText = styled(Text)`
  font-size: 14px;
`;

export const MobileEntry = {
  Container: MobileEntryContainer,
  Title: MobileEntryTitle,
  Text: MobileEntryText,
};

export default MobileEntry;
