import React, { useContext, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import ClientContext from 'app/src/contexts/ClientContext';
import { useParams } from 'app/src/utils/routing';
import DataState from 'app/src/elements/DataState';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Button } from 'app/src/elements/buttons';
import {
  Text,
  Title,
  TitleLinks,
  TitleWithLinks,
  Subtitle,
  Container,
  Additional,
} from 'app/src/styles';
import { Link, useNavigate } from 'app/src/utils/routing';

import CommissionDetails from './CommissionDetails';
import DetailFields from './DetailFields';
import DestroyOrder from './Destroy';
import { FIELDS } from './new';
import { ORDER, UPDATE_ORDER } from './queries';
import setupOrderFields from './setupOrderFields';

import DealerOrders from 'app/src/components/Dealers/Orders';
import { DEALER } from 'app/src/components/Dealers/queries';

const EditOrder = () => {
  const {
    currentUser, activeProducts, isMobile, isTablet
  } = useContext(ClientContext);

  const navigate = useNavigate();

  const [dealer, setDealer] = useState();
  const [showDestroy, setShowDestroy] = useState();

  const { id } = useParams();
  const { data, ...dataState } = useQuery(ORDER, { variables: { id } });

  const [getDealer] = useLazyQuery(DEALER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setDealer(data.dealer),
  });

  useEffect(() => {
    if (data && data.order.dealer.id) {
      getDealer({ variables: { id: data.order.dealer.id }});
    }
  }, [data]);

  const {
    control, handleSubmit, reset, setValue, watch, formState: { errors }
  } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  const values = watch();

  useEffect(() => {
    if (data) {
      const values = defaultValues({
        fields: FIELDS, resource: data.order
      });

      reset({ template: 'full', ...values });
    }
  }, [data]);

  if (!currentUser?.isEmployee) {
    return null;
  }

  const [updateOrder] = useMutation(UPDATE_ORDER, {
    onCompleted: data => navigate(`/orders/${data.updateOrder.order.id}/print`)
  });

  const onSubmit = input => {
    updateOrder({ variables: prepareInput(input, FIELDS) });
  };

  const orderFields = {
    ...setupOrderFields({ dealer, values, activeProducts })
  };

  if (data?.order.filledAt) {
    orderFields.note = {
      hint: (
        'This order has been marked as printed. A note explaining the ' +
        'update is required.'
      ),
      rules: { required: true },
    }
  } else {
    orderFields.note = {
      hint: 'Optional note explaining the update.'
    };
  }

  return (
    <View>
      <DataState data={data} {...dataState}>
        <TitleWithLinks>
          <Title withLinks>Edit Order</Title>

          <TitleLinks>
            <Link to={`/orders/${data?.order.id}`}>
              <Text>⤇ Order Details</Text>
            </Link>

            {data?.order.canDestroy && (
              <Pressable onPress={() => setShowDestroy(true)}>
                <Text>⊘ Destroy Order</Text>
              </Pressable>
            )}
          </TitleLinks>
        </TitleWithLinks>

        {dealer && (
          <Container isTablet={isTablet}>
            <View style={{ flex: 1, zIndex: 500 }}>
              <View
                style={{
                  flexDirection: 'row', gap: 10, alignItems: 'baseline'
                }}
              >
                <Subtitle>{dealer.name}</Subtitle>
                <Link to={`/dealers/${dealer.id}`}>
                  <FontAwesomeIcon icon={faEye} color="green" size={24} />
                </Link>
              </View>
              <DetailFields
                dealer={dealer}
                orderFields={orderFields}
                handleSubmit={handleSubmit(onSubmit)}
                control={control}
                errors={errors}
                setValue={setValue}
                values={values}
                getDealer={getDealer}
                isEdit
              />
            </View>

            <Additional isTablet={isTablet}>
              <CommissionDetails dealer={dealer} />
              <DealerOrders
                title="Past Orders"
                orders={dealer.orders}
                isMobile={isMobile}
              />
            </Additional>
          </Container>
        )}
        {showDestroy && data && (
          <DestroyOrder order={data.order} setShow={setShowDestroy} />
        )}
      </DataState>
    </View>
  );
};

export default EditOrder;
