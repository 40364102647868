import React, { useContext } from 'react';
import { View } from 'react-native';

import Entry from 'app/src/styles/mobileTable';
import { datetimeShort } from 'app/src/utils/formatting';

const MobileSendgridRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  const statusStyle = 'delivered' === entry.status ?
    {} : { color: 'red', fontWeight: 600 };

  return (
    <Entry.Container>
      <Entry.Title>{entry.subject}</Entry.Title>
      <Entry.Text style={statusStyle}>{entry.status}</Entry.Text>
      {!!(entry.opensCount || entry.clicksCount) && (
        <Entry.Text style={{ fontWeight: 600 }}>Opened</Entry.Text>
      )}
      <Entry.Text>Last Event: {datetimeShort(entry.lastEventTime)}</Entry.Text>
    </Entry.Container>
  );
};

export default MobileSendgridRow;
