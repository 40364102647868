import React from 'react';
import { View } from 'react-native';

import Invoices from 'app/src/components/Invoices';
import SerialBlocks from 'app/src/components/SerialBlocks';
import { TitleLinks, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import Members from './Members';

const Financial = () => {
  return (
    <View>
      <TitleLinks style={{ justifySelf: 'right' }}>
        <Link to="/supplies">
          <Text>⤇ Supplies Inventory</Text>
        </Link>

        <Link to="/notes">
          <Text>⤇ General Notes</Text>
        </Link>
      </TitleLinks>

      <View style={{ gap: 24 }}>
        <Invoices />
        <Members />
        <SerialBlocks />
      </View>
    </View>
  );
};

export default Financial;
