import React, { useContext } from 'react';
import { SafeAreaView, ScrollView, Text } from 'react-native';
import { ApolloProvider } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import { Router } from 'app/src/utils/routing';
import Routes from 'app/src/routes';
import tableStyles from 'app/src/styles/table';

import ChangePassword from 'app/src/components/Auth/ChangePassword';
import Header from 'app/src/components/Header';
import Menu from 'app/src/components/Menu';
import MobileMenu from 'app/src/components/Menu/Mobile';
import Footer from 'app/src/components/Footer';
import ScrollToTop from 'app/src/elements/ScrollToTop';

const Screen = styled(SafeAreaView)`
  min-height: 100vh;
`;

const Content = styled(ScrollView)`
  margin: 20px;
`;

const Main = () => {
  const { apolloClient, currentUser, isMobile } = useContext(ClientContext);

  if (!currentUser) { return <Loading />; }

  return (
    <Router>
      <ScrollToTop />
      <ApolloProvider client={apolloClient}>
        <Screen>
          <Header linkToHome />
          {currentUser.needChangePassword ? (
            <Content>
              <ChangePassword expired />
            </Content>
          ) : (
            <>
              {isMobile ? <MobileMenu /> : <Menu />}

              <Content nestedScrollEnabled={true}>
                <DataTable.Provider
                  pageLength={25}
                  lengthMenu={ [10, 25, 50, 'All'] }
                  styles={tableStyles}
                  htmlTable
                >
                  <Routes />
                </DataTable.Provider>
              </Content>
            </>
          )}

          <Footer />
        </Screen>
      </ApolloProvider>
    </Router>
  );
};

export default Main;
