import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';
import { useMutation } from '@apollo/client';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { Loading } from 'app/src/elements/DataState';
import Modal from 'app/src/elements/Modal';
import { Picker } from 'app/src/elements/Picker';
import { Subtitle, Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

import { UPDATE_USER } from './queries';

const DealerRow = styled(View)`
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
`;

const RemoveButton = styled(Pressable)`
  border-width: 1px;
  padding: 2px;
  width: fit-content;
  border-style: solid;
  border-color: red;
`;

const EditUser = ({ user, setShow, refetch }) => {
  const { currentUser } = useContext(ClientContext);

  const [userDealers, setUserDealers] = useState(
    user.userDealers.map(userDealer => ({
      dealerId: userDealer.dealer.id,
      role: userDealer.admin ? 'admin' : 'normal',
    }))
  );

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  if (!currentUser) { return <Loading />; }

  const getRole = dealer => (
    userDealers.find(entry => entry.dealerId === dealer.id)?.role
  );

  const setRole = (dealer, role) => {
    const userDealer = userDealers.find(entry => entry.dealerId === dealer.id);

    if (userDealer) {
      userDealer.role = role;
      setUserDealers([ ...userDealers ]);
    } else {
      setUserDealers([ ...userDealers, { dealerId: dealer.id, role } ]);
    }
  };

  const clearRoles = () => {
    setUserDealers(
      userDealers.map(userDealer => ({ ...userDealer, role: '' }))
    );
  };

  const handleSubmit = () => {
    updateUser({
      variables: {
        id: user.id,
        userDealersAttributes: userDealers
      }
    });
  };

  const dealersCanAdmin = currentUser.userDealers
    .filter(userDealer => userDealer.admin)
    .map(userDealer => userDealer.dealer)
    .sort((dealer, otherDealer) => dealer.name < otherDealer.name ? -1 : 1);

  return (
    <Modal title="Edit User" setShow={setShow}>
      <View style={{ marginBottom: 10 }}>
        <Subtitle>{user.fullName}</Subtitle>
        <Text>{user.email}</Text>
      </View>

      <View style={{ marginBottom: 10 }}>
        <Subtitle>Dealer Access</Subtitle>
        <Text>
          Note: Users with an Administrator role can edit access for other
          users for that dealer.
        </Text>

        <RemoveButton onPress={clearRoles}>
          <Text>Remove All Access to Dealers I administer</Text>
        </RemoveButton>
      </View>

      {dealersCanAdmin.map(dealer => (
        <DealerRow key={dealer.id}>
          <Switch
            activeTrackColor={colors.lightButton}
            activeThumbColor={colors.button}
            value={!!getRole(dealer)}
            onValueChange={value => setRole(dealer, value ? 'normal' : '')}
          />

          <Text>{dealer.label}</Text>

          {getRole(dealer) && (
            <Picker
              selectedValue={getRole(dealer)}
              onValueChange={value => setRole(dealer, value)}
            >
              <Picker.Item value="admin" label="Administrator" />
              <Picker.Item value="normal" label="Normal User" />
            </Picker>
          )}
        </DealerRow>
      ))}

      <Button label="Save" onPress={handleSubmit} wide />
    </Modal>
  );
};

export default EditUser;
