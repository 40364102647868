import React, { useContext } from 'react';
import { View } from 'react-native';
import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components/native';

import CommissionDetails from 'app/src/components/Orders/CommissionDetails';
import config from 'app/src/config';
import { ClientContext } from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import Modal from 'app/src/elements/Modal';
import { Text, Subtitle } from 'app/src/styles';
import { cityStateZip } from 'app/src/utils/address';

import { DEALER_FOR_REVIEW, REVIEW_DEALER } from './queries';
import Users from './Users';

const DetailContainer = styled(View)`
  ${props => (props.isMatch && !props.isMobile) ? `
    border-left-width: 1px;
    border-left-color: gray;
    border-left-style: solid;
    padding-left: 10px;
  ` : ''
  }

  ${props => props.isMobile ? `
    border-top-width: 1px;
    border-top-color: black;
    border-top-style: solid;
    margin-top: 10px;
  ` : ''
  }

  margin-right: 10px;
`;

const DealerDetails = ({ dealer, isMatch, isMobile, submit }) => {
  return (
    <DetailContainer isMatch={isMatch} isMobile={isMobile}>
      <Subtitle>{isMatch ? 'Match' : 'Submitted'}</Subtitle>
      <Subtitle>{dealer.name}</Subtitle>
      {dealer.licenses.map(license => (
        <Text key={license.id}>
          License: {license.licenseType} {license.licenseNumber}
        </Text>
      ))}
      <Text style={{ marginTop: 10 }}>{dealer.street}</Text>
      <Text>{cityStateZip(dealer)}</Text>

      <Text style={{ marginTop: 10 }}>Phone: {dealer.phone}</Text>

      <View style={{ marginTop: 10 }}>
        <Users dealer={dealer} />
      </View>

      {isMatch ? (
        <Button
          label="Approve and Merge"
          onPress={() => submit('Approved', false, dealer.id)}
        />
      ) : (
        <Button
          label="Approve"
          onPress={() => submit('Approved')}
        />
      )}
    </DetailContainer>
  );
};

const Review = ({ dealer, setShow, refetch }) => {
  const { isMobile } = useContext(ClientContext);

  const { data } = useQuery(DEALER_FOR_REVIEW, {
    variables: { id: dealer.id }
  });

  const [reviewDealer] = useMutation(REVIEW_DEALER, {
    onCompleted: () => {
      setShow(false);
      refetch();
    }
  });

  const submit = (approvalStatus, createSampleOrders, mergeIntoId) => {
    if (confirm(`Change status to ${approvalStatus}`)) {
      reviewDealer({
        variables: {
          id: dealer.id,
          mergeIntoId,
          approvalStatus,
          createSampleOrders: !!createSampleOrders,
        }
      });
    }
  };

  return (
    <Modal title="Review Pending Dealer" setShow={setShow}>
      {data?.dealer.matches.length > 0 && (
        <View style={{ borderBottomWidth: 1 }}>
          <Text style={{ fontWeight: 600 }}>
            Found {data?.dealer.matches.length} dealer(s) that might be a match.
            If the match looks right, go to it and choose "Approve and Merge".
          </Text>
        </View>
      )}

      <View style={isMobile ? {} : { flexDirection: 'row', gap: 10 }}>
        {data?.dealer && (
          <View>
            <DealerDetails dealer={data.dealer} submit={submit} />

            <Button
              label="Reject"
              onPress={() => submit('Rejected')}
              type="Cancel"
            />
          </View>
        )}

        <View style={{ marginTop: 10 }}>
          <CommissionDetails dealer={dealer} />
        </View>

        {data?.dealer.matches.map(match => (
          <DealerDetails
            key={match.id}
            dealer={match}
            submit={submit}
            isMobile={isMobile}
            isMatch
          />
        ))}
      </View>

      {config.app.isDemo && (
        <Button
          label="Approve and Create Sample Orders"
          onPress={() => submit('Approved', true)}
          wide
        />
      )}
    </Modal>
  );
};

export default Review;
