import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useMutation } from '@apollo/client';

import ClientContext from 'app/src/contexts/ClientContext';
import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import Modal from 'app/src/elements/Modal';
import mobileTable from 'app/src/elements/mobileTable';
import RelayTable from 'app/src/elements/RelayTable';
import { Title, Text } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import MobileNameDiffersLastOrderRow from './MobileNameDiffersLastOrderRow';
import { DEALERS, RESOLVE_DIFFERS_LAST_ORDER_NAME } from './queries';

const ChooseName = ({ dealer, suggestedName, setShow, refetch }) => {
  const [name, setName] = useState(suggestedName);

  const [resolve] = useMutation(RESOLVE_DIFFERS_LAST_ORDER_NAME, {
    onCompleted: data => {
      refetch();
      setShow(false);
    }
  });

  const handleSubmit = () => {
    resolve({ variables: { id: dealer.id, name } });
  };

  return (
    <Modal title="Choose Name" setShow={setShow}>
      <StyledTextInput value={name} onChangeText={setName} />
      <Button label="Save" onPress={handleSubmit} wide />
    </Modal>
  );
};

const NameDiffersLastOrder = () => {
  const { isMobile } = useContext(ClientContext);
  const [showChooseName, setShowChooseName] = useState(false);
  const [refetch, setRefetch] = useState();

  const columns = [
    {
      key: 'id',
      label: 'ID',
    },
    {
      key: 'name',
      label: 'Name',
      content: ({ entry, value }) => (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Text>{value}</Text>
          <Pressable onPress={() => setShowChooseName({ entry, value })}>
            <Text>[Choose]</Text>
          </Pressable>
        </View>
      )
    },
    {
      key: 'lastOrderName',
      label: 'Last Order Name',
      content: ({ entry, value }) => (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Text>{value}</Text>
          <Pressable onPress={() => setShowChooseName({ entry, value })}>
            <Text>[Choose]</Text>
          </Pressable>
        </View>
      )
    },
    {
      key: 'licenses',
      label: 'License #s',
      sort: 'prevent',
      value: ({ value }) => value.map(license => (
        `${license.licenseType}${license.licenseNumber}`
      )).join(', '),
    },
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <View style={{ flexDirection: 'row', gap: 10 }}>
          <Link to={`/dealers/${entry.id}`}>
            <ActionText>View</ActionText>
          </Link>
          <Link to={`/dealers/${entry.id}/edit`}>
            <ActionText>Edit</ActionText>
          </Link>
        </View>
      )
    },
  ];

  return (
    <View>
      <Title>Dealers with name differing from last order</Title>

      <RelayTable
        query={DEALERS}
        queryName="dealers"
        queryVariables={{ scopes: ['name_differs_last_order'] }}
        columns={columns}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileNameDiffersLastOrderRow }}
      />

      {showChooseName && (
        <ChooseName
          setShow={setShowChooseName}
          dealer={showChooseName.entry}
          suggestedName={showChooseName.value}
          refetch={refetch}
        />
      )}
    </View>
  );
};

export default NameDiffersLastOrder;
