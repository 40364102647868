import React, { useContext, useState } from 'react';
import { View, Pressable, Switch } from 'react-native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Loading } from 'app/src/elements/DataState';
import mobileTable from 'app/src/elements/mobileTable';
import RelayTable from 'app/src/elements/RelayTable';
import { Title, Setting, SettingLabel } from 'app/src/styles';
import colors from 'app/src/styles/colors';
import { Action, ActionText, CellText } from 'app/src/styles/table';
import { dateShort } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

import EditFeatureSubscription from './edit';
import MobileRow from './MobileRow';
import { FEATURE_SUBSCRIPTIONS } from './queries';

const baseColumns = [
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value.label,
    content: ({ entry, value }) => (
      <Link to={`/dealers/${entry.dealer.id}`}>
        <CellText>{value}</CellText>
      </Link>
    )
  },
  {
    key: 'createdAt',
    label: 'Date Subscribed',
    content: dateShort,
  },
  {
    key: 'fullStatus',
    label: 'Status',
    sort: 'prevent',
  },
  {
    key: 'user',
    label: 'User',
    sort: 'prevent',
    value: ({ value }) => value.fullName,
  },
];

const FeatureSubscriptions = () => {
  const { isMobile } = useContext(ClientContext);
  const [showEdit, setShowEdit] = useState();
  const [refetch, setRefetch] = useState();
  const [scopes, setScopes] = useState([]);

  const queryVariables = { scopes };

  const columns = [
    ...baseColumns,
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        entry.deletedAt ? null : (
          <Pressable onPress={() => setShowEdit(entry)}>
            <ActionText>Update or Cancel</ActionText>
          </Pressable>
        )
      ),
    },
  ];

  return (
    <View>
      <Title>
        Subscriptions for Pre-Registration of Temporary Tags Purchased from
        Other Vendors
      </Title>

      <Setting>
        <SettingLabel>Only Show Active Subscriptions</SettingLabel>
        <Switch
          value={scopes.includes('active')}
          onValueChange={value => setScopes(value ? ['active'] : [])}
          activeTrackColor={colors.lightButton}
          activeThumbColor={colors.button}
        />
      </Setting>

      <RelayTable
        query={FEATURE_SUBSCRIPTIONS}
        queryName="featureSubscriptions"
        queryVariables={queryVariables}
        columns={columns}
        pageLength={25}
        setRefetch={setRefetch}
        component={isMobile ? mobileTable : null}
        componentProps={{ rowComponent: MobileRow }}
      />

      {showEdit && (
        <EditFeatureSubscription
          featureSubscription={showEdit}
          setShow={setShowEdit}
          refetch={refetch}
        />
      )}
    </View>
  );
};

export default FeatureSubscriptions;
