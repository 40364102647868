import React from 'react';
import { View } from 'react-native';

import { Text, Warning, WarningText } from 'app/src/styles';

import { nameToLabel } from './forms';
import { valueFromPath } from './inputs';

const FormErrors = ({ errors, fields }) => {
  if (!errors) { return null; }
  if (0 === Object.keys(errors).length) { return null; }

  return (
    <Warning style={{ gap: 12 }}>
      <WarningText style={{ fontSize: 20 }}>
        Unable to submit. Please correct errors on the following
        {1 === Object.keys(errors).length ? ' field' : ' fields'}:
      </WarningText>

      <View>
        {Object.keys(errors).map(name => (
          <View key={name} style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 600 }}>
              {fields[name].label || nameToLabel(name)}
            </Text>
            <Text>: {valueFromPath(errors, name)?.message || 'Required'}</Text>
          </View>
        ))}
      </View>
    </Warning>
  );
};

export default FormErrors;
