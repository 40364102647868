import React from 'react';

const ExternalLink = ({ url, children, target, spaces }) => {
  return (
    <a
      href={url}
      target={target || '_blank'}
      rel="nofollow"
      style={{textDecoration: 'none'}}
    >
      {spaces ? ' ' : ''}{children}{spaces ? ' ' : ''}
    </a>
  );
};

export default ExternalLink;
