import React from 'react';
import { View } from 'react-native';

import Entry from 'app/src/styles/mobileTable';
import { dateShort } from 'app/src/utils/formatting';
import { Link } from 'app/src/utils/routing';

const MobileRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  return (
    <Entry.Container>
      <Link to={`/dealers/${entry.id}`}>
        <Entry.Title>
          {entry.dealer.label}
        </Entry.Title>
      </Link>
      <Entry.Text>
        Subscribed {dateShort(entry.createdAt)} by {entry.user.fullName}
      </Entry.Text>
      <Entry.Text>{entry.fullStatus}</Entry.Text>
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobileRow;
