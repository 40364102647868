import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const NonAdr = () => {
  return (
    <View>
      <Subtitle>
        Pre-Registering Temporary Tags Purchased from Other Authorized Vendors
      </Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          If you have purchased 10-day temporary tags from vendors other than
          Auto Dealers Resource, you can still use OklahomaTempTag.com
          to pre-register sales. However, this does require a service
          subscription.
        </Text>

        <View style={{ marginTop: 10 }}>
          <Text>
            Steps to Pre-Register (and setup a subscription if needed):
          </Text>

          <Step number={1}>
            Click "Pre-Register Sale" in the top menu.
          </Step>

          <Step number={2}>
            Click "Pre-Register Temporary Tag not purchased from ADR
          </Step>

          <Step number={3}>
            If you do not have an active subscription, a message will display
            at the top of the screen. Click the green button labeled
            "Click to Subscribe". You will be redirected to a Stripe
            checkout screen to complete the transaction. Once subscribed,
            you can continue with the pre-registration.
          </Step>

          <Step number={4}>
            <Text>
              <Link to="/how-to/pre-register">
                Complete the pre-registration as normal
              </Link>, but make sure to double check the temporary tag
              serial number since this can not be autocompleted for tags
              purchased from other vendors.
            </Text>
          </Step>
        </View>

        <Text>
          Subscriptions are valid for three months. It will auto-renew until
          you cancel it. Subscriptions belong to the dealership, so are
          valid for all users with access to the dealership. However, if
          a user has access to multiple dealerships, each dealership
          will need its own subscription.
        </Text>

        <View style={{ flex: 1 }}>
          <Text>
            To view or manage subscriptions, click "Account" in the top
            menu, then click "Subscriptions". If you need to make a change,
            click "Update or Cancel" in the last column.
          </Text>

          <Text>
              • To update your payment information, click "Update Payment Method".
            You will receive an emailed link within one business day that can
            be used to make updates.
          </Text>

          <Text>
            • To cancel, click "Cancel Subscription" and confirm.
          </Text>
        </View>
      </View>
    </View>
  );
};

export default NonAdr;
