import React, { useContext } from 'react';
import { View } from 'react-native';

import Entry from 'app/src/styles/mobileTable';

const MobileUserDealerRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  return (
    <Entry.Container>
      <Entry.Title>{entry.dealer.label}</Entry.Title>
      {entry.admin && (
        <Entry.Text style={{ fontWeight: 600 }}>Admin</Entry.Text>
      )}
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobileUserDealerRow;
