import React, { useState } from 'react';
import { View } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import styled from 'styled-components/native';

import { Button } from 'app/src/elements/buttons';
import DataState from 'app/src/elements/DataState';
import { DateTimePicker } from 'app/src/elements/DateTimePicker';
import { StyledTextInput } from 'app/src/elements/inputs';
import { Text, Title, Hint } from 'app/src/styles';
import { delimited } from 'app/src/utils/formatting';
import { useNavigate, useParams } from 'app/src/utils/routing';

import { SUPPLIES, PERFORM_INVENTORY } from './queries';

const Row = styled(View)`
  flex-direction: row;
  gap: 20px;
  align-items: baseline;
`;

const Label = styled(Text)`
  width: 300px;
`;

const nodes = collection => (
  collection && collection.edges.map(edge => edge.node)
);

const PerformInventory = () => {
  const [formStatus, setFormStatus] = useState();
  const navigate = useNavigate();
  const [checkedOn, setCheckedOn] = useState(DateTime.now().toISODate());
  const [quantities, setQuantities] = useState({});

  const { data, ...dataState } = useQuery(SUPPLIES, {
    variables: { limit: 1000, offset: window.btoa(0) }
  });

  const [performInventory] = useMutation(PERFORM_INVENTORY, {
    onCompleted: () => navigate('/supplies'),
  });

  const handleSubmit = () => {
    setFormStatus('submitting');

    const quantityInputs = [];
    Object.keys(quantities).forEach(supplyId => {
      if (quantities[supplyId]) {
        quantityInputs.push({
          supplyId,
          quantity: parseInt(quantities[supplyId])
        });
      }
    });

    performInventory({
      variables: {
        checkedOn,
        quantities: quantityInputs,
      }
    });
  };

  return (
    <View>
      <DataState data={data} {...dataState}>
        <Title>Perform Inventory</Title>

        <Row style={{ marginBottom: 20 }}>
          <Label>Date of Inventory:</Label>
          <DateTimePicker
            value={checkedOn}
            type="date"
            mode="date"
            onChange={e => setCheckedOn(e.target.value)}
          />
        </Row>

        <Hint>
          Note: If not inventorying a supply, leave its quantity blank.
        </Hint>

        {nodes(data?.supplies)?.map(supply => (
          <Row key={supply.id}>
            <Label>
              {supply.name}{' - '}
              {delimited(supply.tagsPerPack)} tags per {supply.packType}
            </Label>
            <Text>Quantity: </Text>
            <StyledTextInput
              value={quantities[supply.id] || ''}
              onChangeText={value => {
                setQuantities({ ...quantities, [supply.id]: value })
              }}
            />
          </Row>
        ))}

        <Button
          label="Save"
          onPress={handleSubmit}
          disabled={'submitting' === formStatus}
        />
      </DataState>
    </View>
  );
};

export default PerformInventory;
