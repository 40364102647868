import React, { useContext, useEffect } from 'react';
import { View } from 'react-native';
import { Controller } from 'react-hook-form';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';

import AddressStreetInput from 'app/src/elements/AddressStreetInput';
import Autocomplete from './Autocomplete';
import AutocompleteSerialNumber from './Autocomplete/SerialNumber';
import ProductSelect from './ProductSelect';
import Input, { Field, FieldLabel } from './inputs';

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_PATTERN =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const ZIP_CODE_PATTERN = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const nameToLabel = name => (
  name.match(/^[a-z]+|[A-Z][a-z]*/g).map(word => (
    word[0].toUpperCase() + word.substr(1).toLowerCase()
  )).join(' ')
);

export const defaultValues = ({ fields, resource }) => {
  const values = {};

  if (resource) {
    values.id = resource.id;
  }

  Object.keys(fields).forEach(name => {
    if (name.endsWith('Id')) {
      const associationName = name.substring(0, name.length - 2)
      values[name] = (resource || {})[associationName]?.id || '';
    } else if (name.includes('.') && resource) {
      values[name.split('.')[0]] = resource[name.split('.')[0]];
    } else {
      values[name] = (resource || {})[name] || '';
    }
  });

  return values;
};

export const prepareInput = (input, fields) => {
  Object.keys(fields).forEach(name => {
    const field = fields[name];

    if (['Int', 'int', 'multiplier'].includes(field.type)) {
      const intValue = String(input[name] || '').replace(/[^-\d\.]/g, '');
      input[name] = '' === intValue ? null : parseInt(intValue);
    }

    if ('null' === field.blank && !input[name]) {
      input[name] = null
    }

    if ('boolean' === field.type) {
      input[name] = !!input[name];
    }
  });

  return input;
};

const DEFAULT_OPTION = { value: '', label: 'Select' };

// Separate component to allow simplify using useEffect when
// oneOption === 'no-choice'
const Select = ({ name, control, errors, field, setValue }) => {
  const client = useContext(ClientContext);

  let options = field.select;

  if (field.clientSelect) {
    options = [DEFAULT_OPTION, ...(client[field.clientSelect] || [])];
  } else if (!options[0].value && !options[0].label) {
    options = [
      ...options.map(option => ({ value: option, label: option }))
    ];
  }

  useEffect(() => {
    if ('no-choice' === field.oneOption && setValue) {
      if (2 === options.length) {
        setValue(name, client[field.clientSelect][0].value);
      }
    }
  }, []);

  if ('no-choice' === field.oneOption && setValue) {
    if (2 === options.length) {
      return (
        <Field>
          <FieldLabel
            name={name}
            label={field.label || nameToLabel(name)}
            errors={errors}
          />
          <Text style={{fontWeight: 'bold'}}>{options[1].label}</Text>
        </Field>
      );
    }
  }

  return (
    <Input.Picker
      label={field.label || nameToLabel(name)}
      name={name}
      key={name}
      hint={field.hint}
      rules={field.rules}
      options={options || []}
      control={control}
      errors={errors}
    />
  );
};

export const Fields = ({ fields, control, errors, setValue }) => {
  return (
    Object.keys(fields).map((name, fieldIndex) => {
      const field = fields[name];

      const generalInputProps = {
        name,
        key: name,
        label: (field.label || nameToLabel(name)),
        hint: field.hint,
        docs: field.docs,
        rules: field.rules,
        control,
        errors,
        setValue,
        fieldIndex,
      };

      if (field.autocomplete) {
        return (
          <Field key={name} style={{ zIndex: 9000 - fieldIndex }}>
            <FieldLabel
              name={name}
              label={field.label || nameToLabel(name)}
              errors={errors}
              hint={field.hint}
            />

            <Controller
              control={control}
              name={name}
              rules={field.rules}
              render={() => (
                'serialNumber' === field.autocomplete.componentName ? (
                  <AutocompleteSerialNumber
                    value={field.value || ''}
                    {...field.autocomplete}
                  />
                ) : (
                  <Autocomplete
                    value={field.value || ''}
                    {...field.autocomplete}
                  />
                )
              )}
            />
          </Field>
        );
      } else if (field.select || field.clientSelect) {
        return (
          <Select field={field} {...generalInputProps} />
        );
      } else if ('boolean' === field.type) {
        return (
          <Input.Switch {...generalInputProps} />
        );
      } else if ('date' === field.type) {
        return (
          <Input.Date {...generalInputProps} />
        );
      } else if ('product' === field.type) {
        return (
          <ProductSelect {...generalInputProps} {...field.inputProps} />
        );
      } else if ('multiplier' === field.type) {
        return (
          <Input.Multiplier {...generalInputProps} {...field.inputProps} />
        );
      } else if ('addressStreet' === field.type) {
        return (
          <AddressStreetInput {...generalInputProps} {...field.inputProps} />
        );
      } else {
        return (
          <Input.Text {...generalInputProps} {...field.inputProps} />
        );
      }
    })
  );
};
