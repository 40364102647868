import React, { useState } from 'react';
import { View } from 'react-native';
import { useMutation } from '@apollo/client';

import { Button } from 'app/src/elements/buttons';
import { StyledTextInput } from 'app/src/elements/inputs';
import Modal from 'app/src/elements/Modal';
import { Text } from 'app/src/styles';

import { SET_DEALER_NICKNAME } from './queries';

const Nickname = ({ dealer, setShow, refetch }) => {
  const currentNickname =
    dealer.label === dealer.defaultLabel ? '' : dealer.label;

  const [nickname, setNickname] = useState(currentNickname);
  const [formStatus, setFormStatus] = useState();

  const [setDealerNickname] = useMutation(SET_DEALER_NICKNAME, {
    onCompleted: () => {
      refetch();
      setShow(false);
    }
  });

  const handleSubmit = () => {
    setFormStatus('Submitting...');
    setDealerNickname({ variables: { dealerId: dealer.id, nickname } });
  };

  const handleClear = () => {
    setFormStatus('Clearing...');
    setDealerNickname({ variables: { dealerId: dealer.id, nickname: '' } });
  };

  return (
    <Modal title="Dealership Nickname" setShow={setShow}>
      <View style={{ borderBottomWidth: 1, marginBottom: 12 }}>
        <Text style={{ fontWeight: 600}}>
          Set a nickname below to use in labels and dropdowns instead of the
        </Text>
        <Text style={{ fontWeight: 600}}>
          default label for this dealership ({ dealer.defaultLabel }).
        </Text>
      </View>

      <View style={{ flexDirection: 'row', gap: 8, alignItems: 'baseline' }}>
        <Text>Nickname:</Text>
        <StyledTextInput
          value={nickname}
          onChangeText={setNickname}
          style={{ flex: 1 }}
        />
      </View>

      {formStatus ? (
        <Text>{formStatus}</Text>
      ) : (
        <>
          <Button label="Set Nickname" onPress={handleSubmit} wide />
          {currentNickname && (
            <Button label="Clear Nickname" onPress={handleClear} wide />
          )}
        </>
      )}
    </Modal>
  );
};

export default Nickname;
