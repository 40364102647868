import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const ref = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView();
    }
  }, [pathname]);

  return <div ref={ref}></div>
};

export default ScrollToTop;
