import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';
import styled from 'styled-components/native';

import { ClientContext } from 'app/src/contexts/ClientContext';
import DataState from 'app/src/elements/DataState';
import minimalTable from 'app/src/elements/minimalTable';
import mobileTable from 'app/src/elements/mobileTable';
import { Text } from 'app/src/styles';
import { datetimeShort } from 'app/src/utils/formatting';

import MobileSendgridRow from './MobileSendgridRow';
import { SENDGRID_HISTORY, DELETE_SENDGRID_UNSUBSCRIBE } from './queries';

const UnsubscribedContainer = styled(View)`
  margin-bottom: 10px;
`;

const Unsubscribed = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

const columns = [
  {
    key: 'subject',
    label: 'Subject',
  },
  {
    key: 'status',
    label: 'Status',
    width: 120,
    content: ({ value }) => (
      'delivered' === value ?
        <Text>{value}</Text> :
        <Text style={{ color: 'red', fontWeight: 600 }}>{value}</Text>
    )
  },
  {
    key: 'opened',
    label: 'Opened?',
    width: 80,
    value: ({ entry }) => (entry.opensCount || entry.clicksCount) ? 'Yes' : '',
  },
  {
    key: 'lastEventTime',
    label: 'Event Time',
    width: 150,
    content: ({ value }) => datetimeShort(value),
  },
];

const Sendgrid = ({ user, email }) => {
  const { isMobile } = useContext(ClientContext);
  const [deleteStatus, setDeleteStatus] = useState();

  const { data, ...dataState } = useQuery(
    SENDGRID_HISTORY, { variables: { email },  fetchPolicy: 'no-cache' }
  );

  const [deleteSendgridUnsubscribe] = useMutation(DELETE_SENDGRID_UNSUBSCRIBE, {
    variables: { email: email },
    onCompleted: () => setDeleteStatus('Delete Request Sent'),
  });

  const handleDeleteSendgridUnsubscribe = () => {
    setDeleteStatus('Deleting...');
    deleteSendgridUnsubscribe();
  };

  return (
    <DataState data={data} {...dataState}>
      {data && (
        <View>
          {data.sendgridHistory.unsubscribedAt && (
            <UnsubscribedContainer>
              <Unsubscribed>
                Unsubscribed {datetimeShort(data.sendgridHistory.unsubscribedAt)}
              </Unsubscribed>

              {deleteStatus ? (
                <Text>{deleteStatus}</Text>
              ) : (
                <Pressable
                  onPress={handleDeleteSendgridUnsubscribe}>
                  <Text style={{ color: 'red', fontWeight: 600 }}>
                    Delete Unsubscribe
                  </Text>
                </Pressable>
              )}
            </UnsubscribedContainer>
          )}

          <Text style={{ color: 'blue' }}>
            Note: This will show up to the last 10 emails sent to this user.
          </Text>
          <Text style={{ color: 'blue' }}>
            Event Time is the most recent of the sent time or some other action,
            like a link clicked.
          </Text>
          <Text style={{ color: 'blue' }}>
            Opened? could be blank even if the user opened it, if they didn't
            click the link.
          </Text>

          <DataTable.Simple
            data={data.sendgridHistory.messages}
            columns={columns}
            pageLength="All"
            component={isMobile ? mobileTable : minimalTable}
            componentProps={{
              rowComponent: MobileSendgridRow,
              minimal: true,
            }}
          />
        </View>
      )}
    </DataState>
  );
};

export default Sendgrid;
