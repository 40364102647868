import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const VoidPreRegistration = () => {
  return (
    <View>
      <Subtitle>
        Voiding Pre-Registration
      </Subtitle>

      <View>
        <Text>
          There are several reasons you may need to void a pre-registration.
          For example:
        </Text>

        <Step number={1}>
          The customer returned the vehicle and the sale was canceled.
        </Step>

        <Step number={2}>
          <Text>
            The pre-registration information was submitted to the state but had
            incorrect information. If the information has not yet been
            submitted to Service Oklahoma, you may also{' '}
            <Link to="/how-to/pre-registration/edit">
              edit instead of voiding.
            </Link>
          </Text>
        </Step>

        <Step number={3}>
          The customer did not receive a metal or stickers from a Licensed
          Operator and Service Oklahoma suggested you re-submit the
          pre-registration.
        </Step>
      </View>

      <View style={{ marginTop: 10 }}>
        <Text>Steps to void a Pre-Registration:</Text>

        <Step number={1}>
          Click "Pre-Registration History" in the top menu.
        </Step>

        <Step number={2}>
          Find the tag and click "Void Sale" in the right-hand column.
        </Step>

        <Step number={3}>
          In the pop-up, click the green "Void Pre-Registration" button.
          You may optionally enter a reason. The reason is not sent to Service
          Oklahoma but is stored for future reference.
        </Step>

        <Step number={4}>
          The void indicator will be sent to Service Oklahoma during the
          next nightly upload.
        </Step>

        <Step number={5}>
          <Text>
            If the pre-registration needs to be re-submitted, you can now do so,
            {' '}
            <Link to="/how-to/pre-register">
              following the same process as the initial pre-registration.
            </Link>
            {' '}
            If you are correcting information, you may resubmit using the
            same temporary tag number. Note that the customer may receive
            multiple metal plates. If so, they should
            retain the last received plate and discard any received earlier.
          </Text>
        </Step>
      </View>

      <View style={{ gap: 10, marginTop: 10 }}>
        <Text>
          Until the void information has been submitted to Service Oklahoma
          (typically around midnight each day), you may remove the void. To
          do so, find the sale again in the Pre-Registration History. Click
          the "VOIDED" link in the right column, then click the green
          "Remove Void" button. If the button is not available, it
          indicates that the void information has already been submitted to
          Service Oklahoma. If needed, you can submit new pre-registration
          information.
        </Text>

        <Text>
          You also have the ability to mark as voided a temporary 10-day
          tag that was not issued to a customer and not associated with
          a pre-registration, for example if there was an error filling
          out the tag or it was damaged. The process is the same, but no
          information about the tag is submitted to Service Oklahoma.
        </Text>
      </View>
    </View>
  );
};

export default VoidPreRegistration;
