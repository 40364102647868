import React from 'react';
import { View } from 'react-native';

import ExternalLink from 'app/src/elements/ExternalLink';
import { Subtitle, Text, Bold } from 'app/src/styles';

const ServiceOklahoma = () => {
  return (
    <View style={{ gap: 10 }}>
      <Subtitle>
        How Is Pre-Registration Data Sent To The State?
      </Subtitle>

      <Text>
        Pre-Registration data entered on OklahomaTempTag.com is uploaded
        to Service Oklahoma each night. All the data submitted on the
        Pre-Registration screen is included. In addition, any pre-registrations
        that were voided after previously being uploaded to Service Oklahoma
        are sent with a void flag so that Service Oklahoma can cancel those
        pre-registrations.
      </Text>

      <Text>
        Once information has been uploaded to Service Oklahoma, the only way
        to change it through OklahomaTempTag.com is to void the pre-registration
        and submit a new pre-registration. Both these can be submitted on the
        same day.
      </Text>

      <Text>
        <Bold spaces>
          OklahomaTempTag.com does not have any access to information about
          the pre-registration processing once it has been uploaded to Service
          Oklahoma.{' '}
        </Bold>
        Service Oklahoma provides more information for dealers at
        <ExternalLink url="https://oklahoma.gov/service/all-services/auto-vehicle/auto-dealer.html" spaces>
          https://oklahoma.gov/service/all-services/auto-vehicle/auto-dealer.html
        </ExternalLink>
        including a button to email them if you are encountering issues or
        have questions.
      </Text>
    </View>
  );
};

export default ServiceOklahoma;
