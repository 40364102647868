import React from 'react';
import { View } from 'react-native';

import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

const PreRegistrationHistory = () => {
  return (
    <View style={{ gap: 10 }}>
      <Subtitle>
        Pre-Registration History and Tag Log
      </Subtitle>

      <Text>
        There are two ways to view your Pre-Registration History.
        The "Pre-Registration History" link in the top menu takes you to
        a list of all pre-reigstrations submitted for your dealership(s).
        If you have multiple dealers connected to your account, a dropdown
        allows you to limit to a single dealership. By default, the tags are
        sorted by time of pre-registration with the most recent first.
        However, you can click the column headers to alter the sort and
        search in the box near the top-right. There is also a button
        to generate and download a spreadsheet.
      </Text>

      <View>
        <Text>
          In the far-right column (or at the bottom of the info for each
          pre-registration if you're on a mobile device) are several links.
          The particular links that display depend on the status of
          pre-registration. Below are the possibilities:
        </Text>

        <Text>
          • "View Details": Click to display a pop-up with details about the
          pre-registration, including who entered the information and if and
          when the information was submitted to the state.
        </Text>

        <Text>
          • "Edit Details": This displays on pre-registrations that have not
          been submitted to the state, so that changes can be made.
          {' '}
          <Link to="/how-to/pre-registration/edit">
            [Click here for more information]
          </Link>
        </Text>

        <Text>
          • "Void Sale" : Once a pre-registration has been submitted, this
          can be used to cancel the pre-registration.
          {' '}
          <Link to="/how-to/pre-registration/void">
            [Click here for more information]
          </Link>
        </Text>

        <Text>
          • "VOIDED": Denotes that the tag has been voided. Click for more
          information. If the void information has not been submitted to the
          state, it is possible to remove the void in the pop-up.
        </Text>
      </View>

      <Text>
        The "Tag Log" has the same information as the "Pre-Registration History"
        but is designed to represent the temporary tag log as was required
        prior to SB 2035. As such, this can be limited to a single order
        of temporary tags. When limited to a single order, the green
        "Print Log" button can be clicked to produce a printable temporary
        tag log.
      </Text>

      <Text>
        The "Tag Log" screen also includes temporary tags that have not yet
        been issued to a customer. Because of this, there are additional
        actions that may be available in this view:
      </Text>

      <View>
        <Text>
          • "Log Tag": For temporary tags that have not been used in a
          pre-registration, this will take you to the pre-gregistration
          screen with the tag's serial number pre-filled.
          {' '}
          <Link to="/how-to/pre-register">
            [Click here for more information]
          </Link>
        </Text>

        <Text>
          • "Void Tag": This can be used to mark temporary tags that were damaged or
          incorrectly filled out and will not be issued to a customer.
        </Text>

        <Text>
          • "VOIDED - Click to change": This displays for temporary tags that
          were voided without being used in a pre-registration in case this
          was done in error.
        </Text>
      </View>
    </View>
  );
};

export default PreRegistrationHistory;
