import React from 'react';
import { View } from 'react-native';

import PreRegisterSale from './PreRegisterSale';
import EmailAndPhone from './EmailAndPhone';
import NonAdr from './NonAdr';
import LicensedOperator from './LicensedOperator';
import PrePregistrationHistory from './History';
import EditPreRegistration from './EditPreRegistration';
import VoidPreRegistration from './VoidPreRegistration';
import ServiceOklahoma from './ServiceOklahoma';

const TagDocs = () => {
  return (
    <View>
      <PreRegisterSale />
      <EmailAndPhone />
      <NonAdr />
      <LicensedOperator />
      <ServiceOklahoma />
      <PrePregistrationHistory />
      <EditPreRegistration />
      <VoidPreRegistration />
    </View>
  );
};

export default TagDocs;
