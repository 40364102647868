import React, { useContext } from 'react';
import { View } from 'react-native';
import { Link } from 'app/src/utils/routing';

import ClientContext from 'app/src/contexts/ClientContext';
import Entry from 'app/src/styles/mobileTable';
import { dateShort, datetimeShort } from 'app/src/utils/formatting';

const MobileRow = ({ entry, columns }) => {
  const { currentUser } = useContext(ClientContext);
  const actionColumn = columns[columns.length - 1];

  const dealers = [];
  if (!currentUser.isEmployee) {
    currentUser.dealers.forEach(dealer => {
      const userDealer = entry.userDealers.find(user => (
        user.dealer.id === dealer.id
      ));

      if (userDealer) {
        dealers.push({
          key: dealer.id,
          label: dealer.label,
          admin: userDealer.admin,
        });
      }
    });
  }

  return (
    <Entry.Container>
      <Entry.Title>{entry.fullName}</Entry.Title>
      <Entry.Text>{entry.email}</Entry.Text>
      {dealers.map(dealer => (
        <View key={dealer.key} style={{ flexDirection: 'row', gap: 12 }}>
          {dealer.admin && (
            <Entry.Text style={{ fontWeight: 600 }}>Admin:</Entry.Text>
          )}
          <Entry.Text>{dealer.label}</Entry.Text>
        </View>
      ))}
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobileRow;
