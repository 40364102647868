import React from 'react';
import { View } from 'react-native';

import Pagination from '@preflighttech/preflight-tables/dist/components/Pagination';

import { StyledTextInput } from 'app/src/elements/inputs';
import { Text } from 'app/src/styles';

const mobileTable = props => {
  const {
    orderedColumns,
    entries,
    updateEntries,
    page,
    numberOfPages,
    buttons,
    searchTerm,
    search,
    disableSearch,
    minimal,
    rowComponent: RowComponent,
  } = props;

  return (
    <View>
      {buttons}

      {!minimal && !disableSearch && (
        <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 10 }}>
          <Text>Search: </Text>
          <StyledTextInput
            style={{ flex: 1, padding: 0 }}
            value={searchTerm || ''}
            onChangeText={value => search(value)}
          />
        </View>
      )}

      {entries.map((entry, index) => (
        <RowComponent
          key={entry.id}
          entry={entry}
          columns={orderedColumns}
          index={index}
        />
      ))}

      {numberOfPages > 1 && (
        <Pagination
          page={page}
          numberOfPages={numberOfPages}
          onPageChange={ newPage => updateEntries({ newPage }) }
          styles={{ container: { justifyContent: 'center' } }}
        />
      )}
    </View>
  );
}

export default mobileTable;
