import React, { useContext } from 'react';
import { View } from 'react-native';

import Entry from 'app/src/styles/mobileTable';
import { currency, delimited } from 'app/src/utils/formatting';

const MobilePendingInvoiceRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  return (
    <Entry.Container>
      <Entry.Title>{currency(entry.orderAmount)}</Entry.Title>
      <Entry.Text>
        Orders: {delimited(entry.orderCount)}{' | '}
        Tags: {delimited(entry.tagCount)}
      </Entry.Text>
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobilePendingInvoiceRow;
