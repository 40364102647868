import React from 'react';
import { View } from 'react-native';

import ExternalLink from 'app/src/elements/ExternalLink';
import { Subtitle, Text } from 'app/src/styles';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const LoginTroubleshooting = () => {
  return (
    <View>
      <Subtitle>Troubleshooting Login Issues</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          Below are some common issues related to logging in. You can also
          email service@oklahomatemptag.com or call 405-232-2947 or
          800-346-4232 for assistance.
        </Text>

        <Text style={{ fontWeight: 600 }}>
          Expired Account:
        </Text>

        <Text>
          You must sign-in at least once every 60 days to keep your account
          active. If you receive a notice that your account has expired,
          send an email to service@oklahomatemptag.com from the email address
          you used to sign up. It may take up to one business day to reactivate
          the account.
        </Text>

        <Text style={{ fontWeight: 600 }}>
          Not receiving login code email, confirmation email or
          reset password email:
        </Text>

        <Text>
          Account emails will come from service@oklahomatemptag.com.
          Check your spam folder and if possible, contact your IT department.
          Email providers like hotmail, yahoo and outlook occassionally delay
          delivery of emails. If possible, whitelist emails from
          service@oklahomatemptag.com.
        </Text>

        <Text>
          From the login screen, you can click the "Not receiving emails?" link.
          This will check our email provider to see if we've received notice
          that your email provider is blocking or deferring our emails.
        </Text>

        <Text>
          If you are having issues receiving two-factor login code emails, you
          can switch to use a two-factor authentication app.{' '}
          <Link to="/how-to/account/login-troubleshooting">
            See here for instructions.
          </Link>
        </Text>
      </View>
    </View>
  );
};

export default LoginTroubleshooting;
