import React, { useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import DataTable from '@preflighttech/preflight-tables';

import { Button } from 'app/src/elements/buttons';
import DataState, { Loading } from 'app/src/elements/DataState';
import { Fields, defaultValues, prepareInput } from 'app/src/elements/forms';
import { Title, Subtitle, Text } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { dateShort, delimited } from 'app/src/utils/formatting';
import { useNavigate, useParams } from 'app/src/utils/routing';

import { FIELDS } from './new';
import { SUPPLY, UPDATE_SUPPLY, DESTROY_INVENTORY } from './queries';

const Supply = () => {
  const [formStatus, setFormStatus] = useState();
  const navigate = useNavigate();

  const { id } = useParams();
  const { data, refetch, ...dataState } = useQuery(SUPPLY, {
    variables: { id }
  });

  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: defaultValues({ fields: FIELDS })
  });

  useEffect(() => {
    if (data) {
      reset({ ...defaultValues({ fields: FIELDS, resource: data.supply }) });
    }
  }, [data]);

  const [updateSupply] = useMutation(UPDATE_SUPPLY, {
    onCompleted: () => navigate('/supplies')
  });

  const [destroyInventory] = useMutation(DESTROY_INVENTORY, {
    onCompleted: () => refetch()
  });

  const onSubmit = input => {
    setFormStatus('submitting');
    updateSupply({ variables: prepareInput(input, FIELDS) });
  };

  const handleDestroyInventory = inventory => {
    const confirmation =
      `Really delete ${inventory.quantity} ${dateShort(inventory.checkedOn)}?`;

    if (confirm(confirmation)) {
      destroyInventory({ variables: { id: inventory.id } });
    }
  };

  const inventoryColumns = [
    {
      key: 'checkedOn',
      label: 'Date',
      content: dateShort,
    },
    {
      key: 'quantity',
      label: 'Quantity',
      content: delimited,
    },
    {
      key: 'tagQuantity',
      label: 'Tag Quantity',
      content: delimited,
    },
    {
      key: 'actions',
      label: 'Actions',
      content: ({ entry }) => (
        <Pressable onPress={() => handleDestroyInventory(entry)}>
          <ActionText>Delete</ActionText>
        </Pressable>
      ),
    }
  ];

  return (
    <View>
      <DataState data={data} {...dataState}>
        <Title>Supply: {data?.supply.name}</Title>

        <Text style={{ fontWeight: 600 }}>
          Predicted Tag Quantity Remaining:{' '}
          {delimited(data?.supply.predictedTagsRemaining)}
        </Text>

        <DataTable.Simple
          data={data?.supply.inventories}
          columns={inventoryColumns}
          pageLength={10}
        />

        <Subtitle bordered>Edit Supply</Subtitle>

        <Fields
          fields={FIELDS}
          control={control}
          errors={errors}
        />

        {'submitting' === formStatus ? (
          <Loading />
        ) : (
          <Button label="Save" onPress={handleSubmit(onSubmit)} wide />
        )}
      </DataState>
    </View>
  );

};

export default Supply;
