import React from 'react';
import { View } from 'react-native';

import ExternalLink from 'app/src/elements/ExternalLink';
import { Subtitle, Text } from 'app/src/styles';

const LicensedOperator = () => {
  return (
    <View>
      <Subtitle>
        Choosing a Licensed Operator to Handle Pre-Registrations
      </Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          Once a pre-registration has been submitted to Service Oklahoma, it is
          assigned to a Licensed Operator (tag agent) to mail the new metal
          plate or stickers. There is not a way to select a Licensed Operator
          via OklahomaTempTag.com. However, you can select a preferred Licensed
          Operator through{' '}
          <ExternalLink url="https://okcars.service.ok.gov/">
            okcars.service.ok.gov
          </ExternalLink>
          , which will then also be used for pre-registrations submitted
          through OklahomaTempTag.com.
        </Text>
      </View>
    </View>
  );
};

export default LicensedOperator;
