import { gql } from '@apollo/client';

const ERRORS = `errors { path, message }`;

const SUPPLY_CORE_FIELDS = gql`
  fragment SupplyCoreFields on Supply {
    id
    name
    packType
    tagsPerPack
  }
`;

export const SUPPLY_FIELDS = gql`
  ${SUPPLY_CORE_FIELDS}
  fragment SupplyFields on Supply {
    ...SupplyCoreFields
  }
`;

export const SUPPLY = gql`
  ${SUPPLY_FIELDS}
  query Supply($id: ID!) {
    supply(id: $id) {
      ...SupplyFields
      predictedTagsRemaining
      inventories {
        id
        checkedOn
        quantity
        tagQuantity
      }
    }
  }
`;

export const SUPPLIES = gql`
  ${SUPPLY_FIELDS}
  query Supplies (
    $limit: Int!
    $offset: String!
    $options: ConnectionOptionsInput
  ) {
    supplies (
      first: $limit
      after: $offset
      options: $options
    ) {
      edges {
        node {
          ...SupplyFields
        }
      }
      totalCount
    }
  }
`;

const SUPPLY_ARGUMENTS = `
  $name: String!
  $packType: String!
  $tagsPerPack: Int!
`;

const SUPPLY_VARIABLES = `
  name: $name
  packType: $packType
  tagsPerPack: $tagsPerPack
`;

export const CREATE_SUPPLY = gql`
  ${SUPPLY_CORE_FIELDS}
  mutation CreateSupply (
    ${SUPPLY_ARGUMENTS}
  ) {
    createSupply (
      ${SUPPLY_VARIABLES}
    ) {
      supply {
        ...SupplyCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const UPDATE_SUPPLY = gql`
  ${SUPPLY_CORE_FIELDS}
  mutation UpdateSupply (
    $id: ID!
    ${SUPPLY_ARGUMENTS}
  ) {
    updateSupply (
      id: $id
      ${SUPPLY_VARIABLES}
    ) {
      supply {
        ...SupplyCoreFields
      }
      ${ERRORS}
    }
  }
`;

export const PERFORM_INVENTORY = gql`
  mutation PerformInventory (
    $checkedOn: ISO8601Date!
    $quantities: [InventoryQuantityInput!]!
  ) {
    performInventory (
      checkedOn: $checkedOn
      quantities: $quantities
    ) {
      ${ERRORS}
    }
  }
`;

export const DESTROY_INVENTORY = gql`
  mutation DestroyInventory ($id: ID!) {
    destroyInventory (id: $id) {
      ${ERRORS}
    }
  }
`;
