import React, { useContext } from 'react';
import { View } from 'react-native';
import { Link } from 'app/src/utils/routing';

import ClientContext from 'app/src/contexts/ClientContext';
import Entry from 'app/src/styles/mobileTable';
import { dateShort, datetimeShort } from 'app/src/utils/formatting';

const MobileRow = ({ entry, columns }) => {
  const { currentUser } = useContext(ClientContext);
  const actionColumn = columns[columns.length - 2];
  const adminActionColumn = columns[columns.length - 1];

  const licenses = [
    entry.newLicense?.licenseString,
    entry.usedLicense?.licenseString,
  ].filter(value => value).join(' / ')

  return (
    <Entry.Container>
      <Entry.Title>
        {currentUser.isEmployee ?
          datetimeShort(entry.filledAt) : dateShort(entry.filledAt)
        }
      </Entry.Title>
      {'dealer' === columns[0].key && (
        <Link to={`/dealers/${entry.dealer.id}`}>
          <Entry.Text style={{ fontWeight: 600 }}>
            {entry.dealer.label}
          </Entry.Text>
        </Link>
      )}
      <Entry.Text>
        {entry.quantity} tags: {entry.serialSummaryShort}
      </Entry.Text>
      <Entry.Text>
        {entry.product.name} | {licenses} | Order # {entry.shopifyOrderNumber}
      </Entry.Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {actionColumn.content({ entry })}
        {currentUser.isEmployee && adminActionColumn.content({ entry })}
      </View>
    </Entry.Container>
  );
};

export default MobileRow;
