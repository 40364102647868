import React from 'react';
import { View } from 'react-native';
import { Subtitle, Text } from 'app/src/styles';

import Video from 'app/src/elements/Video';
import { Link } from 'app/src/utils/routing';

import { Step } from '../elements';

const PreRegisterSale = () => {
  return (
    <View>
      <Subtitle>Pre-Registering Sales</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          You can use oklahomatemptag.com to pre-register vehicle sales in
          accordance with SB2035.
        </Text>

        <Text>
          See this video for more information or the steps below.
        </Text>
      </View>

      <Video
        url="https://www.youtube.com/embed/_ARoo5G9-X0?si=IWYYZca8uGPNdQrq"
        title="oklahomatemptag.com pre-register sale"
      />

      <View style={{ marginTop: 10 }}>
        <Text>Steps to Pre-Register a Vehicle Sale:</Text>

        <Step number={1}>
          Click "Pre-Register Sale" in the top menu.
        </Step>

        <Step number={2}>
          <View style={{ flex: 1 }}>
            <Text>Choose the type of tag used for the pre-registration:</Text>

            <Text>
              • Keep Metal Tag - your customer will be keeping their current metal
              tag to use with the purchased vehicle. They will receive stickers
              from a Licensed Operator (tag agent) to affix to their tag.
            </Text>

            <Text>
              • Temporary Tag - choose this if you are issuing the customer a
              10-day temporary tag purchased from Auto Dealers Resource. Your
              customer will receive a new metal plate from a Licensed Operator.
            </Text>

            <Text>
              • Pre-Register Temporary Tag not purchased from ADR -
              choose this if you are issuing a temporary tag purchased from
              another vendor. This requires a subscription, which you can
              purchase from the next screen.{' '}
              <Link to="/how-to/pre-registration/non-adr">
                Click here for more information.
              </Link>
            </Text>
          </View>
        </Step>

        <Step number={3}>
          If you have more than one dealership set up, choose the dealer
          the vehicle is purchased from.
        </Step>

        <Step number={4}>
          Enter the tag number. For temp tags purchased from ADR, an dropdown
          will display so you can select the tag number. If the tag number
          has already been used, a warning will display.
        </Step>

        <Step number={5}>
          If the date of sale is not today, change the date under
          "Date Vehicle Sold and Tag Issued". For temporary tags, a note will
          display showing the expiration date that should be written on the
          temporary tag.
        </Step>

        <Step number={6}>
          Enter the VIN. We will attempt to decode the VIN and fill in other
          vehicle information. The decoding service is not always successful,
          so you may need to enter vehicle information manually.
        </Step>

        <Step number={7}>
          Enter any missing vehicle details. If you cannot find the make, type
          and select "Other". "Color" will always need to be filled in manually.
          Purchase Price and Trade In Value are optional.
        </Step>

        <Step number={8}>
          Enter the purchaser name and address. When entering the street
          address, the software will attempt to suggest addresses once enough
          characters (around 6) are typed. You can choose a suggested address
          to fill in other fields. Purchaser email and phone are optional but
          they can be used by the Service Oklahoma and the Licensed Operator
          to update your customer on the status of their pre-registration and to
          contact them if there are any issues or questions.
        </Step>

        <Step number={9}>
          <View style={{ flex: 1 }}>
            <Text>
              Click "Save". If there is an error saving, a message will display
              above the button. Possible types of errors are:
            </Text>

            <Text>
              • Missing or invalid data - The message will say "Unable to
              submit. Please correct errors on the following fields:", followed
              by a list of fields and a short note of the issue. Use this as a
              guide to complete the missing data, then click "Save" again.
            </Text>

            <Text>
              • Invalid temporary tag number - ensure that the tag number
              entered matches the printed number.
            </Text>

            <Text>
              • Duplicate tag number or VIN submission - VIN and tag number
              pre-registrations must be unique during the pre-registration
              period (60 days). If you need to correct a pre-registration,
              there will be a button allowing you to {' '}
              <Link to="/how-to/pre-registration/void">void</Link>{' '}
              the prior pre-registration. You will then be able to submit.
            </Text>
          </View>
        </Step>
      </View>
    </View>
  );
};

export default PreRegisterSale;
