
import React from 'react';
import { View } from 'react-native';

import Entry from 'app/src/styles/mobileTable';
import { Link } from 'app/src/utils/routing';

const MobileNoRecentOrderRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  return (
    <Entry.Container>
      <Link to={`/dealers/${entry.id}`}>
        <Entry.Title>
          {entry.name}
        </Entry.Title>
      </Link>
      <Entry.Text>
        {entry.licenses.map(license => license.licenseString).join(', ')}
      </Entry.Text>
      {entry.activeMember && (<Entry.Text>OIADA Member</Entry.Text>)}
      <Entry.Text>
        {entry.preRegistrationSubscriptionStatus || 'No Subscription'}
      </Entry.Text>
      <Entry.Text>{entry.registeredTagCount} Pre-Registrations</Entry.Text>
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobileNoRecentOrderRow;
