import React from 'react';
import { View } from 'react-native';

import CreateAccount from './Create';
import LoginTroubleshooting from './LoginTroubleshooting';
import TwoFactor from './TwoFactor';

const AccountDocs = () => {
  return (
    <View>
      <CreateAccount />
      <LoginTroubleshooting />
      <TwoFactor />
    </View>
  );
};

export default AccountDocs;
