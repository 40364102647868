import React, { useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import DataTable from '@preflighttech/preflight-tables';
import styled from 'styled-components/native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import Notes from 'app/src/components/Notes/List';
import ClientContext from 'app/src/contexts/ClientContext';
import DataState from 'app/src/elements/DataState';
import minimalTable from 'app/src/elements/minimalTable';
import mobileTable from 'app/src/elements/mobileTable';
import { Subtitle, Text } from 'app/src/styles';
import { Actions, Action, ActionText } from 'app/src/styles/table';
import { useParams, Link } from 'app/src/utils/routing';

import MobileUserDealerRow from './MobileUserDealerRow';
import Sendgrid from './Sendgrid';
import {
  USER,
  GENERATE_USER_OTP,
  UPDATE_USER_OTP_TYPE,
  GENERATE_LOGIN_TOKEN,
  GENERATE_PASSWORD_LINK,
  RESET_USER_LAST_ACTIVITY,
  UPDATE_USER,
} from './queries';

const Button = styled(Pressable)`
  border-width: 1px;
  border-style: solid;
  border-color: black;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 4px;
`;

const Hint = styled(Text)`
  color: blue;
  max-width: 700px;
`;

const CopyText = ({ label, value }) => {
  const [clipboardWriteAt, setClipboardWriteAt] = useState();

  const addToClipboard = () => {
    navigator.clipboard.writeText(value || '');
    setClipboardWriteAt(new Date().getTime());
  };

  return (
    <View
      style={{ flexDirection: 'row', gap: 10, marginTop: 20, marginBottom: 20 }}
    >
      <Text>{label}: {value}</Text>
      <Pressable onPress={() => addToClipboard(value)}>
        <FontAwesomeIcon
          icon={faCopy}
          title="Copy to Clipboard"
          color={
            (clipboardWriteAt &&
              clipboardWriteAt + 5000 > new Date().getTime()
            ) ?  'green' : 'black'
          }
        />
      </Pressable>
    </View>
  );
};

const LoginDetails = ({ user, refetch }) => {
  const [otp, setOtp] = useState();
  const [loginLink, setLoginLink] = useState();
  const [passwordLink, setPasswordLink] = useState();
  const [showSendgrid, setShowSendgrid] = useState();

  const [generateUserOtp] = useMutation(GENERATE_USER_OTP, {
    variables: { id: user.id },
    onCompleted: data => setOtp(data.generateUserOtp.token)
  });

  const [updateUserOtpType] = useMutation(UPDATE_USER_OTP_TYPE, {
    onCompleted: refetch
  });

  const [resetUserLastActivity] = useMutation(RESET_USER_LAST_ACTIVITY, {
    variables: { id: user.id },
    onCompleted: refetch,
  });

  const disableOtp = () => {
    if (window.confirm('Really disable 2-factor auth for this user?')) {
      updateUserOtpType({ variables: { id: user.id, otpType: 'none' } });
    }
  };

  const enableOtp = () => {
    if (window.confirm('Really re-enable 2-factor auth for this user?')) {
      updateUserOtpType({ variables: { id: user.id, otpType: 'email' } });
    }
  };

  const [generateLoginToken] = useMutation(GENERATE_LOGIN_TOKEN, {
    variables: { id: user.id },
    onCompleted: data => {
      setLoginLink(
        `${window.location.origin}?loginToken=${data.generateLoginToken.token}`
      );
    }
  });

  const [generatePasswordLink] = useMutation(GENERATE_PASSWORD_LINK, {
    variables: { id: user.id },
    onCompleted: data => setPasswordLink(data.generatePasswordLink.link)
  });

  let otpLabel = 'Email (default)';

  switch (user.otpType) {
    case 'none':
      otpLabel = 'Disabled';
      break;
    case 'app':
      otpLabel = 'App-based';
      break;
  }

  return (
    <>
      <Subtitle>Login Details</Subtitle>

      {user.isExpired && (
        <Button onPress={resetUserLastActivity}>
          <Text style={{ fontWeight: 600 }}>
            User account has expired due to no activity in past 60 days.
          </Text>
          <Text>
            Click to reactive account.
          </Text>
        </Button>
      )}

      <Text>Two-Factor Type: {otpLabel}</Text>

      {'email' === user.otpType && (
        <Button onPress={disableOtp}>
          <Text>Disable 2-factor email</Text>
          <Hint>
            If the user is not receiving emails with 2-factor auth, this will
            remove that requirement. Check with them that they are okay removing
            this security feature.
          </Hint>
        </Button>
      )}

      {'none' === user.otpType && (
        <Button onPress={enableOtp}>
          <Text>Re-enable 2-factor email</Text>
        </Button>
      )}

      {user.confirmedAt ? (
        <>
          {'email' === user.otpType && (
            otp ? (
              <CopyText label="2 Factor Code" value={otp} />
            ) : (
              <Button onPress={generateUserOtp}>
                <Text>Generate 2-factor code</Text>
                <Hint>
                  If the user is on a screen asking for a 2-factor code, you
                  can use this button to generate a code and tell them over
                  the phone. It's probably not worth trying to communicate this
                  over email because the code is only valid for 15 minutes.
                </Hint>
                <Hint style={{ color: 'red' }}>
                  Wait until the user has entered their email and their screen
                  asks for the two-factor code before pressing this or the
                  code will not be valid
                </Hint>
              </Button>
            )
          )}

          {loginLink ? (
            <CopyText label="Login Link" value={loginLink} />
          ) : (
            <Button onPress={generateLoginToken}>
              <Text>Generate Login Link</Text>
              <Hint>
                If the user needs to get logged in ASAP, this is the quick
                option. Just click here and send the link.
              </Hint>
            </Button>
          )}

          {passwordLink ? (
            <CopyText label="Reset Password Link" value={passwordLink} />
          ) : (
            <Button onPress={generatePasswordLink}>
              <Text>Generate Reset Password Link</Text>
              <Hint>
                If the user needs to reset their password, but are not receiving
                the link via email.
              </Hint>
            </Button>
          )}
        </>
      ) : (
        <>
          <Text style={{ fontWeight: 600 }}>User is not confirmed</Text>

          {passwordLink ? (
            <CopyText label="Confirmation Link" value={passwordLink} />
          ) : (
            <Button onPress={generatePasswordLink}>
              <Text>Generate New Confirmation Link</Text>
              <Hint>
                The user has to get through this link before they can do
                anything else.
              </Hint>
            </Button>
          )}
        </>
      )}

      <Button
        onPress={() => setShowSendgrid(!showSendgrid)}
        style={{ marginTop: 10 }}
      >
        <Text>Show Sendgrid History</Text>
      </Button>

      {showSendgrid && (
        <Sendgrid email={user.email} />
      )}
    </>
  );
};

const baseColumns = [
  {
    key: 'dealer',
    label: 'Dealer',
    value: ({ value }) => value.label,
  },
  {
    key: 'admin',
    label: 'Admin?',
    width: 100,
    value: ({ value }) => value ? 'Yes' : ''
  },
];

const User = ({ id, setShow, screenRefetch }) => {
  if (!id) { id = useParams().id; }

  const { currentUser, isMobile } = useContext(ClientContext);

  const { data, refetch, ...dataState } = useQuery(USER, { variables: { id } });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetch();
      if (screenRefetch) { screenRefetch(); }
    }
  });

  if (!currentUser?.isEmployee) {
    return null;
  }

  const changeRole = ({ dealer, role }) => {
    const confirmation = role ?
      `Really change role to ${role} for ${dealer.name}` :
      `Really completely remove access to ${dealer.name}`;

    if (confirm(confirmation)) {
      updateUser({
        variables: {
          id,
          userDealersAttributes: [{ dealerId: dealer.id, role }],
        }
      });
    }
  };

  const columns = [
    ...baseColumns,
    {
      key: 'actions',
      label: 'Actions',
      sort: 'prevent',
      content: ({ entry }) => (
        <Actions>
          <Action first>
            <Link to={`/dealers/${entry.dealer.id}`}>
              <ActionText>View Dealer</ActionText>
            </Link>
          </Action>

          <Action>
            <Pressable
              onPress={() => changeRole({
                dealer: entry.dealer,
                role: entry.admin ? 'normal' : 'admin',
              })}
            >
              <ActionText>
                {entry.admin ? 'Remove Admin' : 'Make Admin'}
              </ActionText>
            </Pressable>
          </Action>

          <Action>
            <Pressable
              onPress={() => changeRole({ dealer: entry.dealer, role: '' })}
            >
              <ActionText>Remove Access</ActionText>
            </Pressable>
          </Action>
        </Actions>
      )
    },
  ];

  return (
    <View>
      <DataState data={data} {...dataState}>
        {data?.user && (
          <View>
            <Subtitle>{data.user.fullName}</Subtitle>
            <Text>{data.user.email}</Text>
            <View style={{ height: 10 }}></View>

            <DataTable.Simple
              data={data.user.userDealers}
              columns={columns}
              pageLength="All"
              component={isMobile ? mobileTable : minimalTable}
              componentProps={{
                rowComponent: MobileUserDealerRow,
                minimal: true,
              }}
            />

            <LoginDetails user={data.user} refetch={refetch} />
            <Subtitle bordered>Notes</Subtitle>
            <Notes
              notes={data.user.notes}
              user={data.user}
              refetch={refetch}
            />
          </View>
        )}
      </DataState>
    </View>
  );
};

export default User;
