import React from 'react';
import { View } from 'react-native';
import { DateTime } from 'luxon';

import RelayTable from 'app/src/elements/RelayTable';
import { Title } from 'app/src/styles';
import { ActionText } from 'app/src/styles/table';
import { Link } from 'app/src/utils/routing';

import { NOTES } from './queries';

const columns = [
  {
    key: 'dealer',
    label: 'Dealer',
    sort: 'prevent',
    value: ({ value }) => value?.name,
  },
  {
    key: 'order',
    label: 'Order',
    sort: 'prevent',
    value: ({ value }) => value?.id,
  },
  {
    key: 'createdAt',
    label: 'Time',
    sort: 'desc',
    content: ({ value }) => (
      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT)
    ),
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'body',
    label: 'Body',
    content: ({ entry, value }) => (
      'manual' === entry.type ? value.substring(0, 200) : ''
    )
  },
  {
    key: 'actions',
    sort: 'prevent',
    content: ({ entry }) => (
      <Link to={`/notes/${entry.id}`}>
        <ActionText>View</ActionText>
      </Link>
    )
  },
];

const Notes = () => {
  return (
    <View>
      <Title>Notes</Title>

      <RelayTable
        query={NOTES}
        queryName="notes"
        columns={columns}
      />
    </View>
  );
};

export default Notes;
