import React, { useContext } from 'react';
import { View, Pressable, Modal as ModalBase } from 'react-native';
import styled from 'styled-components/native';

import ClientContext from 'app/src/contexts/ClientContext';
import { Text } from 'app/src/styles';
import colors from 'app/src/styles/colors';

const CenteredView = styled(View)`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
  ${props => props.isMobile ? '' : `
    justify-content: center;
    align-items: center;
  `}
`;

const ModalView = styled(View)`
  ${props => props.isMobile ? `
    max-width: 100vw;
    width: 100%;
  ` : `
    width: fit-content;
    min-width: 50%;
  `}
  height: fit-content;
  min-height: fit-content;
  background-color: white;
`;

const Header = styled(View)`
  background-color: ${colors.header};
  padding: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled(Text)`
  color: white;
  font-weight: 600;
`;

const Close = styled(Text)`
  color: white;
  font-weight: 900;
  margin-left: 20px;
`;

const Content = styled(View)`
  ${props => props.isMobile ? `
    width: 100vw;
    max-height: 90vh;
  ` : `
    max-height: 80vh;
    max-width: 90vw;
  `}
  padding: 20px;
  overflow: auto;
`;

const Modal = ({ title, children, setShow, style }) => {
  const { isMobile } = useContext(ClientContext);

  return (
    <ModalBase
      onRequestClose={() => setShow(false)}
      visible
      transparent
    >
      <CenteredView isMobile={isMobile}>
        <ModalView style={style} isMobile={isMobile}>
          <Header>
            <Title>{title}</Title>
            <Pressable onPress={() => setShow(false)}>
              <Close>X</Close>
            </Pressable>
          </Header>
          <Content isMobile={isMobile}>
            {children}
          </Content>
        </ModalView>
      </CenteredView>
    </ModalBase>
  );
};

export default Modal;
