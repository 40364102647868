import React from 'react';
import { View } from 'react-native';

import ExternalLink from 'app/src/elements/ExternalLink';
import { Subtitle, Text } from 'app/src/styles';

import { Step } from '../elements';

const TwoFactor = () => {
  return (
    <View>
      <Subtitle>Using an App for Two-Factor Auth</Subtitle>

      <View style={{ gap: 10 }}>
        <Text>
          Two-Factor authentication is enabled by default through a code emailed
          to you each time you login. However, You can change to using an
          Authenticator app (such as Google Authenticator, Duo, Authy, or
          others) to get the code. To do so:
        </Text>

        <View>
          <Step number={1}>
            Click "Account" at the top-right of the menu, then click
            "Two-Factor Auth".
          </Step>

          <Step number={2}>
            Click the "Generate QR Code" button.
          </Step>

          <Step number={3}>
            Use your Authenticator app to scan the QR code from your screen.
          </Step>

          <Step number={4}>
            Click "Continue" and follow the prompts to enter a 6-digit code from
            your Authenticator app to verify setup.
          </Step>
        </View>

        <Text>
          With these steps completed, you'll enter the code from the
          Authenticator app when logging in, and will no longer receive codes
          via email. If you wish to switch back to email codes, you can do
          so by going to "Account" -> "Two-Factor Auth" and following the
          instructions to disable and return to receiving two-factor codes via
          email.
        </Text>
      </View>
    </View>
  );
};

export default TwoFactor;
