import React from 'react';

import { Text } from 'app/src/styles';
import Entry from 'app/src/styles/mobileTable';
import { dateShort } from 'app/src/utils/formatting';

const MobileRow = ({ entry, columns }) => {
  const actionColumn = columns[columns.length - 1];

  return (
    <Entry.Container>
      <Entry.Title>Tag {entry.serialNumber}</Entry.Title>
      {entry.soldOn && (
        <>
          <Entry.Text>{entry.vin}</Entry.Text>
          <Entry.Text>
            Sold {dateShort(entry.soldOn)} to {entry.purchaser}
          </Entry.Text>
        </>
      )}
      {actionColumn.content({ entry })}
    </Entry.Container>
  );
};

export default MobileRow;
