import React from 'react';
import { View } from 'react-native';
import { Text } from 'app/src/styles';

export const Step = ({ number, children }) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <Text style={{ minWidth: 32 }}>{number || '•'}.</Text>
      {'string' === typeof children ? <Text>{children}</Text> : children}
    </View>
  );
};
